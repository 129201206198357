import React, { useEffect } from 'react';
import { Search, Trash2, Eye, Edit2, RefreshCcw, FileText, Monitor, BookOpen, Slash, Save, AlertTriangle, ArrowLeft, Edit } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { startEditCliente, startloadClientes } from '../../../../app/store/clientes/thunks';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { loadEquipo, startActEquipo, startDelEquipo, startloadEquipos } from '../../../../app/store/equipos/thunks';
import { AddEquipo } from '../modals/AddEquipo';
import { loadFacturacion, startActFacturacion, startDelFacturacion, startloadFacturacion } from '../../../../app/store/facturacion/thunks';
import { startloadOrdenesCliente } from '../../../../app/store/ordenes/thunks';
import { Link, Navigate, useNavigate } from 'react-router-dom';

// import { URL_API } from "../../apiConnection";

import { connectionFiles } from '../../../../app/filesConnection'

export const EditCustomer = () => {

    const solo_num = (e) => {
        const allowedKeys = [
          'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
        ];
      
        const inputValue = e.target.value;
        const hasDecimal = inputValue.includes('.');
      
        // Si ya existe un punto decimal, solo permitir dos dígitos después de él
        if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
          e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
          return;
        }
      
        if (
          (e.key >= '0' && e.key <= '9') || // Números
          allowedKeys.includes(e.key) // Otras teclas permitidas
        ) {
          return true;
        } else {
          e.preventDefault(); // Evitar cualquier otra tecla
        }
      };

    const dispatch = useDispatch();
    const { tiposClientes } = useSelector(state => state.catalogos);
    const { equipos, status_equipo } = useSelector(state => state.equipos);
    const { facturacion, status_facturacion } = useSelector(state => state.facturacion);
    const { ordenes, status_ordenes } = useSelector(state => state.ordenes);
    // console.log(ordenes);

    const { cliente_edit, isLoading, status } = useSelector(state => state.clientes);
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            nombreCliente: cliente_edit.NOMBRE,
            telefonoCliente: cliente_edit.TELEFONO,
            celularCliente: cliente_edit.CELULAR,
            emailCliente: cliente_edit.EMAIL,
            tipoCliente: cliente_edit.TIPO_CLIENTE,
            nacimientoCliente:cliente_edit.FECHA_NAC
        }
    });

    const goback = () => {
        dispatch(startloadClientes())
    }

    const handledSaveCliente = (data) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por Actualizar los datos del Cliente ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                // dispatch(startActCliente(id));
                dispatch(startEditCliente(data, cliente_edit.ID))
                goback();
            }
        })
    }

    useEffect(() => {
        dispatch(startloadEquipos(cliente_edit.ID));
        dispatch(startloadFacturacion(cliente_edit.ID));
        dispatch(startloadOrdenesCliente(cliente_edit.ID));
    }, []);

    const handledDelEquipo = (id) => {

        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR un equipo asociado a un Cliente, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDelEquipo(id, cliente_edit.ID));
            }
        })
    }

    const handledActEquipo = (id) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR a un equipo asociado a un Cliente, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActEquipo(id, cliente_edit.ID));
            }
        })
    }


    const handledDelFacturacion = (id) => {

        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR un Dato de Facturacion asociado a un Cliente, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDelFacturacion(id, cliente_edit.ID));
            }
        })
    }

    const handledActFacturacion = (id) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR a un Dato de Facturacion asociado a un Cliente, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActFacturacion(id, cliente_edit.ID));
            }
        })
    }


    const handledEditFacturacion = (e) => {
        dispatch(loadFacturacion(e));
    }

    // const handledEditEquipo = (e) => {
    //     dispatch(loadEquipo(e, cliente_edit.ID));
    // }

    const navigate = useNavigate();
    const goNewOrder = () => {
        navigate('/newOrder', { replace: true })
    }

    return (

        <div className='row'>
            <div className='col-12'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item btn-link" onClick={goback}><ArrowLeft size='20' /> Atras</li>
                        <li className="breadcrumb-item active" aria-current="page">Detalles del Cliente</li>
                    </ol>
                </nav>
            </div>

            {/* Detalle del Cliente */}
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <div className="card ">
                    <div className="card-body">
                        <div className="row mb-1">
                            <div className="col-12">
                                <h5 className="fw-bold me-2 border-0">Datos del Cliente: {cliente_edit.ID} </h5>
                            </div>
                        </div>
                        <hr className="my-0" />
                        <form onSubmit={handleSubmit(handledSaveCliente)}>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label className="form-label">Nombre:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("nombreCliente", { required: 'Nombre del cliente es requerido.' })} />
                                        {
                                            errors.nombreCliente &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.nombreCliente.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label">Telefono:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num} {...register("telefonoCliente"
                                            , {
                                                required: 'Telefono del cliente es requerido.',
                                                minLength: { value: 10, message: 'Telefono debe terner minimo 10 digitos' },
                                                maxLength: { value: 10, message: 'Telefono debe terner maximo 10 digitos' }
                                            })} />

                                        {
                                            errors.telefonoCliente &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.telefonoCliente.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label">Celular:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num}
                                            {...register("celularCliente", {
                                                required: 'Celular del cliente es requerido.',
                                                minLength: { value: 10, message: 'Celular debe terner minimo 10 digitos' },
                                                maxLength: { value: 10, message: 'Celular debe terner maximo 10 digitos' }
                                            })}
                                        />
                                        {
                                            errors.celularCliente &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.celularCliente.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="mb-3">
                                        <label className="form-label">Correo electronico:</label>
                                        <input type="email" className="form-control" aria-label="First name" autoComplete='off'
                                            {...register("emailCliente", {
                                                required: 'Correo del cliente es requerido.',
                                                pattern: {
                                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                    message: 'Introduce una direccion de correo',
                                                },
                                            })} />
                                        {
                                            errors.emailCliente &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.emailCliente.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className="mb-3">
                                        <label className="form-label">Fecha de nacimiento:</label>
                                        <input type="date" className="form-control" aria-label="First name" autoComplete='off'
                                            {...register("nacimientoCliente", { required: 'Fecha Nacimiento.' })} />
                                        {
                                            errors.nacimientoCliente &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.nacimientoCliente.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12">
                                    <label className="form-label ">Clasificación de Cliente:</label>
                                    <select className="form-select" {...register('tipoCliente', { required: 'Debe seleccionar la Clasificación de Cliente' })}>
                                        <option value="">Seleccione una clasificacion</option>
                                        {
                                            tiposClientes.map((item, index) => (
                                                item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                    : <option key={index} style={{ display: "none" }} ></option>
                                            ))
                                        }
                                    </select>
                                    {
                                        errors.tipoCliente &&
                                        <div className='text-left-5 mt-2'>
                                            <AlertTriangle size="18" color="#dc3545" />
                                            <span className='text-left-10 fw-bold text-danger'><small>{errors.tipoCliente.message}</small></span>
                                        </div>
                                    }
                                </div>
                                <div className="col-12 text-end mt-3">
                                    <button type="submit" className="btn btn-outline-primary ms-2" >
                                        <RefreshCcw size='20' />  Actualizar
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
                <div className="accordion" id="accordionPanelsStayOpenExample">

                    {/* Ordenes de Servicio */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                <FileText size="20" /> <span className='text-left-10'>Ordenes de Servicio</span>
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                            <div className="accordion-body">

                                <button type="button" className="btn btn-outline-primary mb-2" onClick={goNewOrder}>Agregar Nuevo</button>
                                <hr className='my-0' />
                                <div className="table-responsive p-2 mt-1 table-scrollbar">
                                    <table className="table table-striped table-hover table-sm my-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" className='text-start th-sticky'>FOLIO</th>
                                                <th scope="col" className="text-start th-sticky">TECNICO</th>
                                                <th scope="col" className="text-start th-sticky">EQUIPO</th>
                                                <th scope="col" className="text-center th-sticky">F.RECIBIDO</th>
                                                <th scope="col" className="text-center th-sticky">F.ASIGNACION</th>
                                                <th scope="col" className="text-center th-sticky">F.DIAGNOSTICO</th>
                                                <th scope="col" className="text-center th-sticky">ESTADO</th>
                                                {/* <th scope="col" className="text-center">ACCIONES</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                ordenes.length === 0 ?
                                                    <>
                                                        <tr>
                                                            <td scope="row" colSpan={6}> No se han encontrado datos.</td>
                                                        </tr>
                                                    </> : ordenes.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">
                                                                <Link to={`/trackingOrder/${item.FOLIO}`}>
                                                                    {item.FOLIO}
                                                                </Link>
                                                            </td>
                                                            <td className="text-start">{item.TECNICO_ASIGNADO}</td>
                                                            <td className="text-start">{item.EQUIPO}</td>
                                                            <td className="text-center">{item.F_RECIBIDO}</td>
                                                            <td className="text-center">{item.F_ASIGNACION}</td>
                                                            <td className="text-center">{item.F_DIAGNOSTICO}</td>
                                                            <td className="text-center">
                                                                {item.ESTADO == 0 ? <> <span className="status-btn pending-btn"> Sin Asignar </span>  </> : <> </>}
                                                                {item.ESTADO == 1 ? <> <span className="status-btn revision-btn"> Asignada </span>  </> : <> </>}
                                                                {item.ESTADO == 2 ? <> <span className="status-btn progress-btn"> Sin Diagnostico </span>  </> : <> </>}
                                                                {item.ESTADO == 3 ? <> <span className="status-btn tests-btn"> Con Diagnostico </span>  </> : <> </>}
                                                                {item.ESTADO == 4 ? <> <span className="status-btn vacaciones-btn"> En espera de Componente </span>  </> : <> </>}
                                                                {item.ESTADO == 5 ? <> <span className="status-btn esperacom-btn"> Completa / Terminada </span>  </> : <> </>}
                                                                {item.ESTADO == 6 ? <> <span className="status-btn complete-btn"> No Aprobada a Entrega </span>  </> : <> </>}
                                                                {item.ESTADO == 7 ? <> <span className="status-btn complete-btn"> Aprobada a Entrega </span>  </> : <> </>}
                                                                {item.ESTADO == 8 ? <> <span className="status-btn complete-btn"> Entregada a Cliente </span>  </> : <> </>}
                                                                {item.ESTADO == 9 ? <> <span className="status-btn pending-btn"> Cancelado </span>  </> : <> </>}

                                                                {/* {item.ESTADO} */}
                                                                {/* <span className="status-btn complete-btn text-uppercase"> COMPLETA </span> */}
                                                            </td>
                                                        </tr>
                                                    ))
                                            }


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Equipos Asociados */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                <Monitor size="20" /> <span className='text-left-10'>Equipos Asociados</span>
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                            <div className="accordion-body">
                                <button type="button" className="btn btn-outline-primary mb-2" data-bs-toggle="modal" data-bs-target="#newEquipCustomer">Agregar Nuevo</button>
                                <hr className='my-0' />
                                <div className="table-responsive p-2 mt-1 table-scrollbar">
                                    <table className="table table-striped table-hover table-sm my-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" className='text-start th-sticky'>ID</th>
                                                <th scope="col" className="text-start th-sticky">EQUIPO</th>
                                                <th scope="col" className="text-start th-sticky">S.N.</th>
                                                <th scope="col" className="text-center th-sticky">SIS. OP.</th>
                                                <th scope="col" className="text-center th-sticky">ESTADO</th>
                                                <th scope="col" className="text-center">ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                equipos.length === 0 ?
                                                    <>
                                                        <tr>
                                                            <td scope="row" colSpan={6}> No se han encontrado datos.</td>
                                                        </tr>
                                                    </> :
                                                    equipos.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{item.ID}</td>
                                                            <td className="text-start">{item.MARCA} {item.MODELO}</td>
                                                            <td className="text-start">{item.NO_SERIE}</td>
                                                            <td className="text-center">{item.SIS_OP}</td>
                                                            <td className="text-center">
                                                                {
                                                                    item.ESTADO == 1 ?
                                                                        <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                {/* <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editEquipoCustomer" onClick={e=>handledEditEquipo(item.ID)} >
                                                                    <Edit size="20" />
                                                                </button> */}
                                                                {
                                                                    item.ESTADO == 1 ? <>
                                                                        <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelEquipo(item.ID)}>
                                                                            <Trash2 size="20" />
                                                                        </button></> :
                                                                        <>
                                                                            <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActEquipo(item.ID)}>
                                                                                <RefreshCcw size="20" />
                                                                            </button>
                                                                        </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                        </div>
                    </div>

                    {/* Datos Facturacion */}
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                <BookOpen size="20" /> <span className='text-left-10'> Datos de Facturacion Asociados</span>
                            </button>
                        </h2>
                        <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                            <div className="accordion-body">
                                <button type="button" className="btn btn-outline-primary mb-2" data-bs-toggle="modal" data-bs-target="#newFacturacion">Agregar Nuevo</button>
                                <hr className='my-0' />
                                <div className="table-responsive p-2 mt-1 table-scrollbar">
                                    <table className="table table-striped table-hover table-sm my-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" className='text-start th-sticky'>ID</th>
                                                <th scope="col" className="text-start th-sticky">RFC</th>
                                                <th scope="col" className="text-start th-sticky">RAZON SOCIAL</th>
                                                <th scope="col" className="text-center th-sticky">REGIMEN</th>
                                                <th scope="col" className="text-center th-sticky">TELEFONO</th>
                                                <th scope="col" className="text-center th-sticky">EMAIL</th>
                                                <th scope="col" className="text-center th-sticky">CSF</th>
                                                <th scope="col" className="text-center th-sticky">ESTADO</th>
                                                <th scope="col" className="text-center">ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {
                                                facturacion.length === 0 ? <>
                                                    <tr>
                                                        <td scope="row" colSpan={8}> No se han encontrado datos.</td>
                                                    </tr>
                                                </> :
                                                    facturacion.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-center">{item.ID}</td>
                                                            <td className="text-center">{item.RFC}</td>
                                                            <td className="text-center">{item.RAZON_SOCIAL}</td>
                                                            <td className="text-center">{item.REGIMEN_FISCAL}</td>
                                                            <td className="text-start">{item.TELEFONO}</td>
                                                            <td className="text-center">{item.CORREO}</td>
                                                            <td>
                                                                <a type="button" href={`${connectionFiles['csfURL']}/${item.CSF}`} target='_blank' className="btn btn-outline-dark border-0">  <Eye size="20" /></a>
                                                            </td>
                                                            <td className="text-center">
                                                                {
                                                                    item.ESTADO == 1 ?
                                                                        <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                                }
                                                            </td>

                                                            <td className="text-center">
                                                                <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#EditFacturacion" onClick={e => handledEditFacturacion(item.ID)} >
                                                                    <Edit2 size="20" />
                                                                </button>
                                                                {/* <button type="button" className="btn btn-outline-primary border-0" data-bs-toggle="modal" data-bs-target="#viewFacturacion" onClick={e => handledEditFacturacion(item.ID)} >
                                                                  
                                                                </button> */}
                                                                {
                                                                    item.ESTADO == 1 ? <>
                                                                        <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelFacturacion(item.ID)}>
                                                                            <Trash2 size="20" />
                                                                        </button></> :
                                                                        <>
                                                                            <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActFacturacion(item.ID)}>
                                                                                <RefreshCcw size="20" />
                                                                            </button>
                                                                        </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))

                                            }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
