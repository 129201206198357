import React from 'react'
import { AlertTriangle, Search } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { startloadReportPostOrders } from '../../../../app/store/reportes/thunks';
import { startloadSaleReport } from '../../../../app/store/sales/thunks';
import { startloadOrden } from '../../../../app/store/ordenes/thunks';
import { Table_2 } from '../../../utils/Table_2';

export const PostOrder = () => {

    const { isLoading, status_report, postOrders } = useSelector(state => state.reportes);
    const { register, handleSubmit, watch, reset, getValues, setValue, formState: { errors } } = useForm({ model: 'all' });
    let f = new Date(); let dia = f.getDate(); let mes = f.getMonth() + 1; if (dia < 10) dia = "0" + dia; if (mes < 10) mes = "0" + mes; let today = f.getFullYear() + "-" + mes + "-" + dia;
    const dispatch = useDispatch();

    const searchOrdenes = (data) => {
        // console.log(data);
        dispatch(startloadReportPostOrders(data.fechaInicial, data.fechaFinal))
    }


    const loadFolio = (folio) => {

        if (folio.includes("HDLV")) {
            dispatch(startloadSaleReport(folio))
        } else {
            dispatch(startloadOrden(folio));
        }

    }

    const dataU = [...postOrders];
    const columnsType1 = [
        {
            header: 'FOLIO',
            dataIndex: 'FOLIO',
            classHeader: 'text-center',
            classRow: 'text-center fw-bold text-primary',
            renderer: (value) => (
                <>
                    <button type="button" className="btn btn-link" data-bs-toggle="modal" data-bs-target="#detailFolio" onClick={() => loadFolio(value)}>{value}</button>
                </>
            ),
        },
        {
            header: 'FECHA CIERRE',
            dataIndex: 'FECHA_CERRADA',
            classHeader: 'text-center',
            classRow: 'text-center',
        },
        {
            header: 'CLIENTE',
            dataIndex: 'CLIENTE',
            classHeader: 'text-start',
            classRow: 'text-start',
        },
        {
            header: 'CELULAR',
            dataIndex: 'CELULAR_CLIENTE',
            classHeader: 'text-center',
            classRow: 'text-center',
        },
        {
            header: 'TELEFONO',
            dataIndex: 'TELEFONO_CLIENTE',
            classHeader: 'text-center',
            classRow: 'text-center',
        },
        {
            header: 'EMAIL',
            dataIndex: 'EMAIL_CLIENTE',
            classHeader: 'text-start',
            classRow: 'text-start',
        },
    ];

    // console.log(postOrders);



    return (
        <div>
            <h2 className="h4  fw-bold">Seguimiento Post Orden de Servicio</h2>
            <div>
                <div className="card-body">

                    <form className='row' onSubmit={handleSubmit(searchOrdenes)} >
                        <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                            <label className="form-label">Fecha de Inicio:</label>
                            <input type="date" className="form-control" {...register("fechaInicial", { required: 'Introduce fecha de inicio' })} />
                            {
                                errors.fechaInicial &&
                                <div className='text-left-5 mt-2'>
                                    <AlertTriangle size="18" color="#dc3545" />
                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.fechaInicial.message}</small></span>
                                </div>
                            }
                        </div>
                        <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2  ">
                            <label className="form-label">Fecha de Termino:</label>
                            <input type="date" className="form-control" defaultValue={today} {...register("fechaFinal")} />
                        </div>

                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-1">
                            <div className="mb-3 mt-4">
                                <button type="submit" className="btn btn-outline-secondary">
                                    <Search size="20" /> Buscar
                                </button>
                            </div>
                        </div>
                    </form>
                    <hr className='my-0' />
                    <h5 className='fw-bold mt-4 mb-2'>Resultados</h5>
                    <Table_2 typeTable={3} columns={columnsType1} data={dataU} msg={'En espera para mostrar datos'} />
                </div>
            </div>
        </div>
    )
}
