import React, { useEffect, useRef, useState } from 'react'
import { AlertOctagon, AlertTriangle, ArrowLeft, ArrowRightCircle, Clipboard, Edit, Eye, File, FilePlus, FileText, Plus, Printer, Repeat, Save, Search, Shield, Slash, Trash2 } from 'react-feather'
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { startCloseOrden, startRegistroDescuentoOrden, startloadOrden } from '../../../app/store/ordenes/thunks';
import { clearStore } from '../../../app/store/ordenes/slice';
import { useDispatch, useSelector } from 'react-redux';
import { ViewEvidencias } from '../orderTracking/modals/ViewEvidencias';
import { Galeria } from '../orderTracking/modals/Galeria';
import { startloadTarifaAlm } from '../../../app/store/catalagos/thunks';
import { AddFacturacion } from '../customers/modals/AddFacturacion';
import { loadCliente } from '../../../app/store/clientes/thunks';
import { startloadFacturacion } from '../../../app/store/facturacion/thunks';
import { SearchFacturacion } from './modals/SearchFacturacion';
import { useNavigate, useParams } from 'react-router-dom';
import { startloadGarantia, startRegistroGarantia } from '../../../app/store/garantias/thunk';

export const OrderClose = () => {

  const { descuentos, status, tarifaAlm, tipos_pagos } = useSelector(state => state.catalogos);
  const { servicios } = useSelector(state => state.catalogos);
  const { usuario, id_emp } = useSelector(state => state.usuario);
  const { facturacion, facturacion_edit } = useSelector(state => state.facturacion);
  const { orden_edit, indicadores, isLoading, status_ordenes } = useSelector(state => state.ordenes);

  // console.log(tarifaAlm);
  
  const [Fotografias, setFotografias] = useState([]);
  const navigate = useNavigate();
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      // toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });

  // const inputRef = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startloadTarifaAlm());
    // inputRef.current.focus();
  }, [])

  const { register, reset, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      textSearch: "",
    }
  });

  const { register: registerCC, trigger: triggerCC, setFocus: setFocusCC, getValues: getValuesCC, setValue: setValueCC, formState: { errors: errorsCC }, handleSubmit: handleSubmitCC, reset: resetCC } = useForm(
    {
      mode: 'all',
      defaultValues: {
        // enciende: 0, cargador_bat: 0, sonido: 0, red: 0, ensamblado: 0, teclado: 0, limpieza: 0, retorno: 0, checklist: 0, solo_rev: 0
      }
    });

  const handledSearch = (data) => {
    reset({});
    dispatch(startloadOrden(data.textSearch))
  }

  const cancel = () => {
    window.location.reload();
  }

  useEffect(() => {
    if (facturacion_edit.length != 0) {
      setValueCC('id_facturacion', facturacion_edit.ID);
      setValueCC('rfc_factruracion', facturacion_edit.RFC);
      setValueCC('razon_soc_facturacion', facturacion_edit.RAZON_SOCIAL);
      setValueCC('regimen_facturacion', facturacion_edit.REGIMEN_FISCAL);
      setValueCC('telefono_facturacion', facturacion_edit.TELEFONO);
      setValueCC('correo_facturacion', facturacion_edit.CORREO);
      setValueCC('direccion_facturacion', facturacion_edit.DIRECCION + ', CP:' + facturacion_edit.COD_POSTAL);
      setValueCC('cp_facturacion', facturacion_edit.COD_POSTAL);
    }

  }, [facturacion_edit])

  const goNewSearch = () => {
    dispatch(clearStore())
  }

  const registerDescuentos = () => {

    let descuento = [];
    let capturante = usuario; let folio = orden_edit[0]?.FOLIO;
    let orden_detail = orden_edit[0]; let servicios = orden_detail.SERVICIOS; let refacciones = orden_edit[0]["REFACCIONES"]; let subtotal_refacciones = 0;
    let diasAlmCobrar = orden_detail.DIAS_ALMACENADO - Number(tarifaAlm.DIAS_GRACIA);
    if (diasAlmCobrar <= 0) diasAlmCobrar = 0;


    let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
    let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
    if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
    let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

    // DESCUENTO ALMACENAMIENTO
    let costoAlm = tarifaAlm.COSTO * (diasAlmCobrar);
    let almacenajeDESC = (costoAlm * (Number(document.getElementById('descAlmacen').value) / 100)).toFixed(2);

    let itemAlmacen = {
      "capturo": capturante,
      "fecha": fechaRegistro,
      "hora": horaRegistro,
      "detalle": folio,
      "comentarios": '',
      "monto": almacenajeDESC,
      "aplicado": 1,
    }

    if (almacenajeDESC != '0.00') {
      descuento.push(itemAlmacen);
    }

    // DESCUENTO SERVICIOS REALIZADOS 
    let subtotal_servicios = 0;
    servicios.forEach(item => {

      if (item.AUTORIZO == 1 && item.ESTADO == 1) {
        subtotal_servicios = subtotal_servicios + Number(item.COSTO);
      }
    });
    let serviciosDESC = (subtotal_servicios * (Number(document.getElementById('descServicios').value)) / 100).toFixed(2);

    let itemServicio = {
      "capturo": capturante,
      "fecha": fechaRegistro,
      "hora": horaRegistro,
      "detalle": folio,
      "comentarios": '',
      "monto": serviciosDESC,
      "aplicado": 2,

    }

    if (serviciosDESC != '0.00') {
      descuento.push(itemServicio);
    }

    // DESCUENTO REFACCIONES
    refacciones.forEach(item => {
      if (item.ESTADO == 5) {
        subtotal_refacciones = subtotal_refacciones + Number(item.COSTO_CLIENTE);
      }

    })
    let refaccionesDESC = (subtotal_refacciones * (Number(document.getElementById('descRefacciones').value) / 100)).toFixed(2);

    let itemRefaccion = {
      "capturo": capturante,
      "fecha": fechaRegistro,
      "hora": horaRegistro,
      "detalle": folio,
      "comentarios": '',
      "monto": refaccionesDESC,
      "aplicado": 3,
    }

    if (refaccionesDESC != '0.00') {
      descuento.push(itemRefaccion);
    }

    // console.log(descuento);

    if (descuento.length != 0) {
      dispatch(startRegistroDescuentoOrden(descuento))
    }

  }

  const closeOrden = () => {

    // registerDescuentos();
    let idFacturacion = getValuesCC('id_facturacion'); let capturante = usuario; let folio = orden_edit[0]?.FOLIO; let recoge = document.getElementById('recoge_text').value;
    // console.log(recoge);
    if (recoge == "") {
      Toast.fire({
        icon: 'error',
        title: 'Debe introducir quien recoge el dispositivo'
      })
      document.getElementById('recoge_text').focus();
      return;
    }

    let flagFacturacion = 0;

    if (document.getElementById("facturacion_div").style.display === 'block') {

      if (getValuesCC('id_facturacion') == "" || getValuesCC('id_facturacion') == undefined) {


        flagFacturacion = 1;
      }
    } else {
      console.log('No existe');
    }

    // console.log(flagFacturacion);


    let total = document.getElementById('total_orden').value.replace(/[$,]/g, '');
    let iva = document.getElementById('iva_orden').value.replace(/[$,]/g, '');
    let subtotal = document.getElementById('subotal_orden').value.replace(/[$,]/g, '');
    let formaPago = document.getElementById('formaPago_orden').value;
    let comentarios = document.getElementById('comentarios_orden').value;
    let pago = document.getElementById('pago_orden').value;
    // console.log(pago);


    if (Pagos.length != 0) {

      let acumulador = 0;

      Pagos.forEach(item => {
        acumulador = acumulador + Number(item.total);
      });

      if (acumulador < total) {
        Toast.fire({
          icon: 'error',
          title: 'Debe realizar el cobro correspondiente para la orden'
        });
        return;
      } else {

        if (flagFacturacion == 1) {

          idFacturacion = 0;
          Swal.fire({
            title: 'Atención',
            text: `Está por cerrar la orden: ${folio}, indico que se requiere factura, No se han indicado sus datos. (Puede ir al menu Facturacion, seccion pendientes a indicarlos, ya que se haya cerrado la orden)
             ¿Desea de continuar con esta acción?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.isConfirmed) {
              // dispatch(startDelCategoria(e));
              registerDescuentos();

              // console.log(Pagos);
              dispatch(startCloseOrden(folio, idFacturacion, capturante, recoge, total, iva, subtotal, formaPago, comentarios, pago, Pagos));
            } else {
              return;
            }
          })
        } else {
          Swal.fire({
            title: 'Atención',
            text: `Está por cerrar la orden: ${folio}, ¿Desea de continuar con esta acción?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.isConfirmed) {
              // dispatch(startDelCategoria(e));
              registerDescuentos();

              // console.log(Pagos);
              dispatch(startCloseOrden(folio, idFacturacion, capturante, recoge, total, iva, subtotal, formaPago, comentarios, pago, Pagos));
            } else {
              return;
            }
          })
        }
      }
    } else {

      if (pago < total) {
        Toast.fire({
          icon: 'error',
          title: 'Debe realizar el cobro correspondiente para la orden'
        });
        return;
      } else {


        if (flagFacturacion == 1) {
          idFacturacion = 0;
          Swal.fire({
            title: 'Atención',
            text: `Está por cerrar la orden: ${folio}, indico que se requiere factura, No se han indicado sus datos. (Puede ir al menu Facturacion, seccion pendientes a indicarlos, ya que se haya cerrado la orden)
             ¿Desea de continuar con esta acción?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.isConfirmed) {
              // dispatch(startDelCategoria(e));
              registerDescuentos();
              // console.log(Pagos);
              dispatch(startCloseOrden(folio, idFacturacion, capturante, recoge, total, iva, subtotal, formaPago, comentarios, pago, Pagos));
            } else {
              return;
            }
          })
        } else {
          Swal.fire({
            title: 'Atención',
            text: `Está por cerrar la orden: ${folio}, ¿Desea de continuar con esta acción?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
          }).then((result) => {
            if (result.isConfirmed) {
              // dispatch(startDelCategoria(e));
              registerDescuentos();
              // console.log(Pagos);
              dispatch(startCloseOrden(folio, idFacturacion, capturante, recoge, total, iva, subtotal, formaPago, comentarios, pago, Pagos));
            } else {
              return;
            }
          })
        }



      }
    }
  }

  const showDivFacturacion = (e) => {
    document.getElementById("facturacion_div").style.display = "block";
  }

  const HideDivFacturacion = (e) => {
    document.getElementById("facturacion_div").style.display = "none";
    setValueCC('id_facturacion', "");
    setValueCC('rfc_factruracion', "");
    setValueCC('razon_soc_facturacion', "");
    setValueCC('regimen_facturacion', "");
    setValueCC('telefono_facturacion', "");
    setValueCC('correo_facturacion', "");
    setValueCC('direccion_facturacion', "");
    setValueCC('cp_facturacion', "");
  }

  let paramsURL = useParams();


  useEffect(() => {
    if (!paramsURL.folio) {
      // console.log('TODO NORMAL');
    } else {
      // console.log('ejecutar busqueda argumento');
      reset({});
      dispatch(startloadOrden(paramsURL.folio))
    }
  }, [paramsURL])


  const [Pagos, setPagos] = useState([]);

  useEffect(() => {
    let totalpago = 0;

    if (document.getElementById('total_orden') != null) {
      let total = document.getElementById('total_orden').value.replace(/[$,]/g, '');

      // console.log(total);
      Pagos.forEach(item => {
        totalpago = totalpago + Number(item.total);
      });

      let cambio = totalpago - total;
      setValueCC('cambio', Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(cambio));
    }

  }, [Pagos]);


  if (isLoading) {
    return (
      <>
        <div className='div_center'>
          <div className='text-center'>
            <div className="spinner"></div>
          </div>
          <div className='text-center text-left-15'>
            <span className='fw-bold'>Cargando</span>
          </div>
          <div className='text-center text-left-15'>
            <div className="spinner"></div>
          </div>
        </div>
      </>
    )
  }

  if (status_ordenes == 'EN_ESPERA' || status_ordenes == 'ORDENES_NO_ENCONTRADOS') {
    return (
      <>
        <div className="mb-3">
          <h1 className="h4 d-inline align-middle fw-bold">Cierre de Orden de Servicio</h1>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
            <div className="card ">

              <form onSubmit={handleSubmit(handledSearch)}>
                <div className="card-body">
                  <label className="mb-1 fw-bold text-dark">Orden de Servicio:</label>
                  <div className="d-flex">
                    <input className="form-control me-2" type="search" aria-label="Search" autoComplete='off' {...register("textSearch", { required: 'Introduce folio de Orden de Servicio' })} 
                    // ref={(e) => {inputRef.current = e;  }} 
                    />
                    <button type='submit' className="btn btn-outline-secondary">
                      <Search size='20' />
                    </button>
                  </div>
                  {
                    errors.textSearch &&
                    <div className='text-left-5 mt-2'>
                      <AlertTriangle size="18" color="#dc3545" />
                      <span className='text-left-10 fw-bold text-danger'><small>{errors.textSearch.message}</small></span>
                    </div>
                  }
                </div>
              </form>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9'>
            <div className="alert alert-light" role="alert">
              <h6 className="fw-bold text-dark h3">Instrucciones para Cerrar  una Orden de Servicio</h6>
              <p className="card-text h4 justify">No es posible dar cierre una Orden de Servicio hasta que la busques, sigue los siguientes pasos...</p>
              <p className="card-text mt-2"><span className='fw-bold'>Paso #1:</span><br /> Introduce el Folio de la Orden de Servicio, estas tienen el prefijo HDL - #####</p>
              <p className="card-text"><span className='fw-bold'>Paso #2:</span><br /> Presiona el Icono de la Lupa para buscar, si es contrada la Orden se cargara la informacion de la misma.</p>
              <p className="card-text"><span className='fw-bold'>Por la dudas:</span><br />
                Si la Orden no es encontrada se te informara a traves de un mensaje y podras realizar una nueva busqueda.
              </p>
              {/* <p className="card-text"></p> */}
            </div>
          </div>
        </div>
      </>
    )
  }


  if (status_ordenes == 'ORDEN_ENCONTRADA') {

    let orden_detail = orden_edit[0]; let servicios = orden_detail.SERVICIOS; let subtotal_servicios = 0; let subtotal_refacciones = 0;
    let evidencias = orden_edit[0]["EVIDENCIAS"]; let CONTROLCC = orden_edit[0].CONTROL_CALIDAD;
    let refacciones = orden_edit[0]["REFACCIONES"];
    let anticipos = orden_edit[0]["ANTICIPOS"]; let total_anticipos = 0;


    servicios.forEach(item => {
      // console.log(item.ID);
      if (item.AUTORIZO == 1 && item.ESTADO == 1) {
        subtotal_servicios = subtotal_servicios + Number(item.COSTO);
      }
    });

    refacciones.forEach(item => {
      if (item.ESTADO == 5) {
        subtotal_refacciones = subtotal_refacciones + Number(item.COSTO_CLIENTE);
      }

    })

    // console.log(subtotal_refacciones);

    anticipos.forEach(item => {
      total_anticipos = total_anticipos + Number(item.MONTO);
    });


    let subtotal = subtotal_refacciones + subtotal_servicios;
    // let balance =subtotal- total_anticipos;
    // console.log(balance);

    // Cobro de Orden de Servicio
    let diasAlmCobrar = orden_detail.DIAS_ALMACENADO - Number(tarifaAlm.DIAS_GRACIA);
    if (diasAlmCobrar <= 0) diasAlmCobrar = 0;

    let costoAlm =Number(tarifaAlm.COSTO) * Number(diasAlmCobrar);

    console.log(costoAlm);
    

    let AcumuladocostoAlmacenaje = costoAlm;

    const changeAcumuladoTarifaAlmacen = (e) => {

      // console.log(costoAlm * (Number(e.target.value) / 100));
      AcumuladocostoAlmacenaje = costoAlm - (costoAlm * (Number(e.target.value) / 100));
      setValueCC('costoAlm', AcumuladocostoAlmacenaje);
      changeTotales();
    }


    let iva_servicios = (subtotal_servicios / 1.16) * 0.16;
    // let iva_servicios = subtotal_servicios * 0.16;
    let acumuladoServicios = subtotal_servicios - iva_servicios;

    const changeAcumuladoServicios = (e) => {

      let val = document.getElementById('valServicios').value.replace(/[$,]/g, '');
      let descAplicar = Number(e.target.value) / 100;
      let valueC = ((Number(val) - (Number(val) * descAplicar)) / 1.16).toFixed(2);

      acumuladoServicios = valueC;
      setValueCC('acumuladoServicios', acumuladoServicios);
      changeTotales();
    }

    let iva_refacciones = (subtotal_refacciones / 1.16) * 0.16;
    // let iva_refacciones = subtotal_refacciones*0.16;
    let acumuladoRefacciones = subtotal_refacciones - iva_refacciones;


    const changeAcumuladoRefacciones = (f) => {

      let valRefa = document.getElementById('valRefacciones').value.replace(/[$,]/g, '');
      let descAplicar = Number(f.target.value) / 100;
      let valueC = ((Number(valRefa) - (Number(valRefa) * descAplicar)) / 1.16).toFixed(2);
      acumuladoRefacciones = valueC;

      setValueCC('acumuladoRefacciones', acumuladoRefacciones);
      changeTotales();
    }

    let subtotal_x = acumuladoServicios + acumuladoRefacciones + AcumuladocostoAlmacenaje;
    let IVA = iva_refacciones + iva_servicios;
    let total = subtotal_x + IVA - total_anticipos;





    const changeTotales = () => {
      // subtotal_x = acumuladoServicios + acumuladoRefacciones + AcumuladocostoAlmacenaje;

      let valRefaccionesTotal = getValuesCC('acumuladoRefacciones');
      let valServiciosTotal = getValuesCC('acumuladoServicios');
      let valAlmacenamientoTotal = getValuesCC('costoAlm');

      if (valAlmacenamientoTotal == undefined || valAlmacenamientoTotal == 'undefined') {
        // console.log('indefinido Almacenamiento');

        let diasAlmCobrar = orden_detail.DIAS_ALMACENADO - Number(tarifaAlm.DIAS_GRACIA);
        let valueC = 0;
        if (diasAlmCobrar > 0) {
          let val = document.getElementById('valAlmQ').value.replace(/[$,]/g, '');
          let desc = document.getElementById('descAlmacen').value;

          valueC = ((Number(val) - (Number(val) * desc)) / 1.16).toFixed(2);
        }

        valAlmacenamientoTotal = valueC;
      }

      if (valRefaccionesTotal == undefined || valRefaccionesTotal == 'undefined') {
        // console.log('indefinido Refacciones');
        let val = document.getElementById('valRefacciones').value.replace(/[$,]/g, '');
        let desc = document.getElementById('descRefacciones').value;

        let valueC = ((Number(val) - (Number(val) * desc)) / 1.16).toFixed(2);
        valRefaccionesTotal = valueC;
      }

      if (valServiciosTotal == undefined || valServiciosTotal == 'undefined') {
        // console.log('indefinido Servicios');
        let val = document.getElementById('valServicios').value.replace(/[$,]/g, '');
        let desc = document.getElementById('descServicios').value;

        let valueC = ((Number(val) - (Number(val) * desc)) / 1.16).toFixed(2);
        valServiciosTotal = valueC;
      }


      // console.log(valRefaccionesTotal);
      // console.log(valServiciosTotal);
      // console.log(valAlmacenamientoTotal);


      subtotal_x = Number(valAlmacenamientoTotal) + Number(valServiciosTotal) + Number(valRefaccionesTotal);

      IVA = (Number(valAlmacenamientoTotal) * 0.16) + (Number(valServiciosTotal) * 0.16) + (Number(valRefaccionesTotal) * 0.16);
      // console.log(total_anticipos);

      total = subtotal_x + IVA - Number(total_anticipos);
      setValueCC('subtotal', Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_x));
      setValueCC('iva', Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(IVA));
      setValueCC('total', Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total));
    }

    const changeCambio = (g) => {
      let pago = g.target.value;
      let total = document.getElementById('total_orden').value.replace(/[$,]/g, '');
      let cambio = 0

      if (Pagos.length != 0) {

      } else {
        cambio = Number(pago) - Number(total);
        setValueCC('cambio', Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(cambio));
      }

    }

    const addFormaPago = () => {
      let pago = document.getElementById('pago_orden').value;
      let formaPago = document.getElementById('formaPago_orden').value;
      let text = document.getElementById('formaPago_orden');
      let selectedIndex = text.selectedIndex;
      let selectedText = text.options[selectedIndex].text;
      let comentarios = document.getElementById('comentarios_orden').value;
      let item = {
        "forma_pago": formaPago,
        "string": selectedText,
        "comentarios": comentarios,
        "total": pago
      }
      setPagos([...Pagos, item]);

    }

    const handleRemovePago = (e) => {
      let arrayANTERIOR = [...Pagos];
      arrayANTERIOR.splice(e, 1);
      setPagos([...arrayANTERIOR]);
    }

    const launchreport = (e) => {
      console.log(orden_edit.folio);
      window.open(`./assets/reports/ordenEntrega.html?folio=${e}`, '_blank');
    }

    if (orden_detail.ESTADO < 7) {
      return (
        <div className='row'>
          <div className='col-12'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item btn-link" onClick={goNewSearch}><ArrowLeft size='20' /> Atras</li>
                <li className="breadcrumb-item active" aria-current="page">Detalles de la Orden</li>
              </ol>
            </nav>
          </div>

          <div className='col-10 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
            <div className="alert alert-light" role="alert">
              <div className='row'>
                <div className='col-1'>
                  <AlertOctagon size='20' />
                </div>
                <div className='col-10 text-start'>
                  <h4>Esta Orden de Servicio no puede ser cerrada hasta que se haya acompletado todo el proceso de seguimiento de Orden...</h4>
                  <button type="button" className="btn btn-outline-dark" onClick={goNewSearch}>
                    Ok. Entiendo, vamos atras....
                  </button>
                </div>
              </div>
            </div>
          </div>


        </div>
      )
    }

    if (orden_detail.ESTADO == 7) {

      // console.log();
      return (
        <>
          <ViewEvidencias /> <Galeria /> <AddFacturacion /> <SearchFacturacion opc={0} />
          <div className="mb-3">
            <h1 className="h4 d-inline align-middle fw-bold">Cierre de Orden de Servicio</h1>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
              <div className="card ">
                <div className="card-body">
                  <label className="mb-1 fw-bold text-dark">Orden de Servicio:</label>
                  <div className="d-flex">
                    <input className="form-control me-2" type="search" aria-label="Search" disabled value={orden_detail.FOLIO} />
                    <button className="btn btn-outline-secondary" onClick={goNewSearch}>
                      <Repeat size='20' />
                    </button>
                  </div>
                </div>
                <hr className="my-0" />
                <div className="card-body">
                  <div className="row mb-1">
                    <div className="col-12">
                      <h5 className="fw-bold me-2 border-0">Datos del Cliente </h5>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row">
                    <div className="col-12 mt-3">
                      <div className="mb-1">
                        <label className="form-label">Nombre:</label>
                        <input type="text" className="form-control" value={orden_detail.NOMBRE} disabled />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-1">
                        <label className="form-label">Telefono:</label>
                        <input type="text" className="form-control" value={orden_detail.TELEFONO} disabled />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-1">
                        <label className="form-label">Celular:</label>
                        <input type="text" className="form-control" value={orden_detail.CELULAR} disabled />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">Correo electronico:</label>
                        <input type="text" className="form-control" value={orden_detail.EMAIL} disabled />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-1">
                    <div className="col-12">
                      <h5 className="fw-bold me-2 border-0">Equipo </h5>
                      <hr className="my-0" />
                    </div>
                    <div className="col-12 mt-1">
                      <div className="mb-2">
                        <label className="form-label">Equipo:</label>
                        <input type="text" className="form-control" value={orden_detail.EQUIPO} disabled />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-2">
                        <label className="form-label">S.N:</label>
                        <input type="text" className="form-control" value={orden_detail.NO_SERIE} disabled />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-2">
                        <label className="form-label">Sistema Operativo:</label>
                        <input type="text" className="form-control" value={orden_detail.SIS_OP} disabled />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-1">
                        <label className="form-label">Cargador: <span> {orden_detail.CARGADOR == 1 ? <> Si </> : <> No </>} </span>
                        </label>
                        <input type="text" className="form-control" value={orden_detail.CARGADOR_SN} disabled />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-1">
                        <label className="form-label">Bateria: <span> {orden_detail.BATERIA == 1 ? <> Si </> : <> No </>}</span></label>
                        <input type="text" className="form-control" value={orden_detail.BATERIA_SN} disabled />
                      </div>
                    </div>

                    <div className="col-12 mt-2">
                      <button type="button" className="btn btn-outline-dark border-0 w-100" data-bs-toggle="modal" data-bs-target="#galery">Ver fotografias</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
              <div className="card">

                <div className="card-body">

                  <h5 className="card-title fw-bold text-dark mb-2">Detalles de trabajo realizado</h5>
                  <hr className="my-0" />
                  {/* dIAGNOSTICO */}
                  <div className="row mt-2">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                      <label className="form-label fw-bold">Descripción de la falla(s):</label>
                      <textarea className="form-control" rows="6" disabled defaultValue={orden_detail.DESCRIPCION_FALLA}></textarea>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                      <label className="form-label fw-bold">Diagnostico:</label>
                      <textarea className="form-control" rows="6" disabled defaultValue={orden_detail.DIAGNOSTICO}></textarea>
                    </div>
                  </div>

                  {/* sERVICIOS */}
                  <h5 className="card-title fw-bold text-dark mb-2 mt-3">Servicios realizados </h5>
                  <div className="row mb-3">
                    <div className="table-responsive mt-1 table-scrollbar-6">
                      <table className="table table-sm table-striped table-hover">
                        <thead>
                          <tr>
                            <th scope="col" className='th-sticky'>#</th>
                            <th scope="col" className='th-sticky'>SERVICIO</th>
                            <th scope="col" className="text-center th-sticky">AUTORIZADO</th>
                            <th scope="col" className="text-center th-sticky">REALIZADO</th>
                            <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                            <th scope="col" className="text-end th-sticky">COSTO</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            servicios.map((item, index) => (
                              <tr key={index} className={item.AUTORIZO != 1 ? 'table-warning' : ''}>
                                <td>{index + 1}</td>
                                <td>{item.NOMBRE}</td>
                                <td className="text-center">
                                  {
                                    item.AUTORIZO == 1 ? <>
                                      <img src="./assets/images/indicators/check.png" width="28" height="28" /></> : <><img src="./assets/images/indicators/close.png" width="28" height="28" /></>
                                  }
                                </td>
                                <td className="text-center" >
                                  {
                                    item.ESTADO == 1 ? <>
                                      <img src="./assets/images/indicators/check.png" width="28" height="28" /></> : <><img src="./assets/images/indicators/close.png" width="28" height="28" /></>
                                  }
                                </td>
                                <td className="text-center">
                                  {
                                    evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                  }
                                </td>
                                <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO)}</td>

                              </tr>
                            ))
                          }

                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={5} className="text-end fw-bold">Subtotal SIN IVA:</td>
                            <td className="fw-bold text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(acumuladoServicios)}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>

                  {/* Refacciones Instaladas */}
                  <h5 className="card-title fw-bold text-dark mb-1 mt-4">Refacciones Instaladas </h5>
                  <div className="row mb-3">
                    <div className="table-responsive mt-1 table-scrollbar-6">
                      <table className="table table-sm table-striped table-hover">
                        <thead>
                          <tr>
                            <th scope="col" className='th-sticky'>#</th>
                            <th scope="col" className='th-sticky'>REFACCIÓN</th>
                            <th scope="col" className="text-center th-sticky">ESTADO</th>
                            <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                            <th scope="col" className="text-end th-sticky">COSTO ($)</th>
                            <th scope="col" className="text-end th-sticky">C. CLIENTE. ($)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            refacciones.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.REFACCION}</td>

                                <td className="text-center">
                                  {item.ESTADO == 0 ? <> <span className="status-btn pending-btn"> Solicitada </span>  </> : <> </>}
                                  {item.ESTADO == 1 ? <> <span className="status-btn revision-btn"> Aprobada </span>  </> : <> </>}
                                  {item.ESTADO == 2 ? <> <span className="status-btn progress-btn"> Comprada, en espera </span>  </> : <> </>}
                                  {item.ESTADO == 3 ? <> <span className="status-btn tests-btn"> Llego </span>  </> : <> </>}
                                  {item.ESTADO == 4 ? <> <span className="status-btn vacaciones-btn"> Disponible </span>  </> : <> </>}
                                  {item.ESTADO == 5 ? <> <span className="status-btn complete-btn"> Instalada </span>  </> : <> </>}
                                  {item.ESTADO == 6 ? <> <span className="status-btn pending-btn"> Rechazo Cliente </span>  </> : <> </>}
                                </td>
                                <td className="text-center">
                                  {
                                    evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                  }
                                </td>

                                <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_HDL)}</td>
                                <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_CLIENTE)}</td>
                              </tr>
                            ))
                          }

                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={5} className="text-end fw-bold">Subtotal SIN IVA:</td>
                            <td className="fw-bold text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(acumuladoRefacciones)}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12'>
                      <div className="text-end">
                        <h5 className="">Subtotal de Servicios y Refacciones: <span
                          className="fw-bold">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal)}</span> </h5>
                      </div>
                    </div>
                  </div>

                  {/* Entrega de Equipo */}
                  <h5 className="card-title fw-bold text-dark mb-2">Entrega de Equipo</h5>
                  <hr className="my-0" />
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="mb-2">
                        <label className="form-label">Quien recoge:</label>
                        <input type="text" className="form-control " id='recoge_text' aria-label="First name" />
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="requierofacura" id="inlineRadio1" value="0" defaultChecked onClick={e => HideDivFacturacion(e)} />
                        <label className="form-check-label" >No, requiero factura</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="requierofacura" id="inlineRadio2" value="1" onClick={e => showDivFacturacion(e)} />
                        <label className="form-check-label" >Si, requiero factura</label>
                      </div>
                    </div>
                  </div>
                  {/* FaCTURACION */}
                  <div id="facturacion_div" className="row mt-4" style={{ display: "none" }}>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                      <h5 className="fw-bold  border-0">Datos de facturación </h5>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-start mb-1">
                      <button type="button" className="btn btn-outline-dark border-0" data-bs-toggle="modal" data-bs-target="#newFacturacion">
                        <Plus size='20' />
                      </button>
                      <button type="button" className="btn btn-outline-dark border-0" data-bs-toggle="modal" data-bs-target="#searchFacturacion">
                        <Search size='20' />
                      </button>
                    </div>
                    <hr className="my-0" />
                    <div className="row mt-2">

                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">

                        <input type="hidden" className="form-control" disabled  {...registerCC("id_facturacion")} />
                        <div className="mb-1">
                          <label className="form-label">RFC:</label>
                          <input type="text" className="form-control" disabled {...registerCC("rfc_factruracion")} />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">

                        <div className="mb-1">
                          <label className="form-label">Razon Social:</label>
                          <input type="text" className="form-control"  {...registerCC("razon_soc_facturacion")} disabled />
                        </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="mb-1">
                          <label className="form-label">Regimen:</label>
                          <input type="text" className="form-control"  {...registerCC("regimen_facturacion")} disabled />
                        </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="mb-1">
                          <label className="form-label">Telefono:</label>
                          <input type="text" className="form-control"  {...registerCC("telefono_facturacion")} disabled />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <div className="mb-1">
                          <label className="form-label">Correo Electronico:</label>
                          <input type="text" className="form-control" {...registerCC("correo_facturacion")} disabled />
                        </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3"></div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9">
                        <div className="mb-1">
                          <label className="form-label">Dirección:</label>
                          <textarea className="form-control" rows="4" {...registerCC("direccion_facturacion")} disabled></textarea>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                        <div className="mb-1">
                          <label className="form-label">CP:</label>
                          <input type="text" className="form-control" {...registerCC("cp_facturacion")} disabled />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* COBRO DE CUENTA */}
                  <div className="row mt-2">
                    <h5 className="fw-bold me-2 border-0">Resumen de la Cuenta </h5>
                    <div className="table-responsive ">
                      <table className="table table-sm">
                        <thead>
                          <tr>
                            <th scope="col">CONCEPTO</th>
                            <th scope="col">COSTO</th>
                            <th scope="col">CANT</th>
                            <th scope="col">DESCUENTO</th>
                            <th scope="col">ACUMULADO</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="row">Almacenamiento</td>
                            <td>
                              <input type="text" className="form-control text-end" id='valAlmQ' style={{ width: '120px' }} defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(tarifaAlm.COSTO)} disabled />
                            </td>
                            <td>
                              <input type="text" className="form-control text-center" disabled defaultValue={diasAlmCobrar} />
                            </td>
                            <td>
                              <select className="form-select text-end" id='descAlmacen' style={{ width: '120px' }} onChange={e => changeAcumuladoTarifaAlmacen(e)}>
                                {
                                  descuentos.map((item, index) => (
                                    item.ESTADO == 1 ? <option key={index} value={item.VALOR} className='text-uppercase' >{item.NOMBRE}</option>
                                      : <option key={index} style={{ display: "none" }} ></option>
                                  ))
                                }
                              </select>
                            </td>
                            <td>
                              <input type="text" className="form-control text-end" style={{ width: '120px' }} id='valAlmTotal' disabled defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(AcumuladocostoAlmacenaje)}  {...registerCC("costoAlm")} />
                            </td>
                          </tr>
                          <tr>
                            <td scope="row" >Servicios Realizados</td>
                            <td >
                              <input type="text" className="form-control text-end " style={{ width: '120px' }} id='valServicios' defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_servicios)} disabled />
                            </td>
                            <td>
                              {/* <!-- <input type="text" className="form-control"> --> */}
                            </td>
                            <td>
                              <select className="form-select text-end " id='descServicios' style={{ width: '120px' }} onChange={f => changeAcumuladoServicios(f)}>
                                {
                                  descuentos.map((item, index) => (
                                    item.ESTADO == 1 ? <option key={index} value={item.VALOR} className='text-uppercase' >{item.NOMBRE}</option>
                                      : <option key={index} style={{ display: "none" }} ></option>
                                  ))
                                }
                              </select>
                            </td>
                            <td>
                              <input type="text" className="form-control text-end" style={{ width: '120px' }} id='valServTotal' disabled defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(acumuladoServicios)}  {...registerCC("acumuladoServicios")} />
                            </td>
                          </tr>

                          <tr>
                            <td scope="row">Refacciones</td>
                            <td>
                              <input type="text" className="form-control text-end" style={{ width: '120px' }} id='valRefacciones' defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_refacciones)} disabled />
                            </td>
                            <td>
                              {/* <!-- <input type="text" className="form-control"> --> */}
                            </td>
                            <td>
                              <select className="form-select text-end" id='descRefacciones' style={{ width: '120px' }} onChange={e => changeAcumuladoRefacciones(e)}>
                                {
                                  descuentos.map((item, index) => (
                                    item.ESTADO == 1 ? <option key={index} value={item.VALOR} className='text-uppercase' >{item.NOMBRE}</option>
                                      : <option key={index} style={{ display: "none" }} ></option>
                                  ))
                                }
                                {/* {
                                  descuentos.map((item, index) => (
                                    item.ESTADO == 1 ? <option key={index} value={item.VALOR} className='text-uppercase' >{item.NOMBRE}</option>
                                      : <option style={{ display: "none" }} ></option>
                                  ))
                                } */}
                              </select>
                            </td>
                            <td>
                              <input type="text" className="form-control text-end" style={{ width: '120px' }} id='valRefaTotal' disabled defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(acumuladoRefacciones)}  {...registerCC("acumuladoRefacciones")} />
                            </td>
                          </tr>

                          <tr>
                            <td scope="row" >Anticipos</td>
                            <td >
                              <input type="text" className="form-control text-end " style={{ width: '120px' }} defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_anticipos)} disabled />
                            </td>
                            <td>
                              {/* <!-- <input type="text" className="form-control"> --> */}
                            </td>
                            <td>
                              {/* <select className="form-select text-end " style={{ width: '120px' }} 
                              onChange={f => changeAcumuladoServicios(f)}
                              >
                                {
                                  descuentos.map((item, index) => (
                                    item.ESTADO == 1 ? <option key={index} value={item.VALOR} className='text-uppercase' >{item.NOMBRE}</option>
                                      : <option key={index} style={{ display: "none" }} ></option>
                                  ))
                                }
                              </select> */}
                            </td>
                            <td>
                              <input type="text" className="form-control text-end" style={{ width: '120px' }} disabled defaultValue={'- ' + Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_anticipos)}  {...registerCC("total_anticipos")} />
                            </td>
                          </tr>

                          <tr className="">
                            <td colSpan="4" className="text-end fw-bold border-0">
                              SUBTOTAL:
                            </td>
                            <td className="border-0">
                              <input type="text" className="form-control text-end" id='subotal_orden' disabled style={{ width: '120px' }} defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_x)} {...registerCC("subtotal")} />
                            </td>
                          </tr>
                          <tr>

                            <td colSpan="4" className="text-end fw-bold border-0">
                              IVA:
                            </td>
                            <td className="border-0">
                              <input type="text" className="form-control text-end" id='iva_orden' disabled style={{ width: '120px' }} defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(IVA)} {...registerCC("iva")} />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4" className="text-end fw-bold border-0">
                              TOTAL A COBRAR:
                            </td>
                            <td className="border-0">
                              <input type="text" className="form-control text-end" id='total_orden' disabled style={{ width: '120px' }} defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total)} {...registerCC("total")} />
                            </td>
                          </tr>
                          {/* <tr>
                            <td colSpan={4} className="text-end fw-bold border-0">
                              FORMA DE PAGO:
                            </td>
                            <td className='border-0 '>
                              
                              <select className="form-select " id='formaPago_orden' style={{ width: '120px' }} >
                                {
                                  tipos_pagos.map((item, index) => (
                                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                      : <option key={index} style={{ display: "none" }} ></option>
                                  ))
                                }
                              </select>
                            </td>
                          </tr> */}
                          {/* <tr>
                            <td colSpan={4} className="text-end fw-bold border-0">
                              PAGO:
                            </td>
                            <td>
                              <input type="number" className="form-control text-end" style={{ width: '120px' }} onChange={g => changeCambio(g)}
                           
                              />
                            </td>
                          </tr> */}
                          <tr>
                            <td colSpan="4" className="text-end fw-bold border-0">
                              CAMBIO:
                            </td>
                            <td className="border-0">
                              <input type="text" className="form-control text-end" id='cambio_orden' disabled style={{ width: '120px' }} {...registerCC("cambio")}
                              // defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(cambio)}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* <button type="button" className="btn btn-primary">Primary</button> */}


                  </div>

                  <hr className="my-0" />

                  <hr className="my-0" />

                  <div className='card-body'>
                    <div className='row'>

                      <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
                        <label className="mb-1 fw-bold text-dark">Forma de Pago</label>
                        <select className="form-select " id='formaPago_orden'  >
                          {
                            tipos_pagos.map((item, index) => (
                              item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                : <option key={index} style={{ display: "none" }} ></option>
                            ))
                          }
                        </select>
                      </div>
                      <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                        <label className="mb-1 fw-bold text-dark">Comentarios</label>
                        <input type="text" className="form-control text-start" defaultValue={'NA'} id='comentarios_orden' />
                      </div>

                      <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                        <label className="mb-1 fw-bold text-dark">Pago</label>
                        <input type="number" className="form-control text-end" id='pago_orden'
                          onChange={g => changeCambio(g)}
                        // style={{ width: '120px' }}
                        />
                      </div>
                      <div className='col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1'>
                        <button type="button" className="btn btn-outline-primary mt-4" onClick={addFormaPago}><Plus size='20' /></button>
                      </div>




                    </div>

                    <div className='row'>
                      <div className='table-responsive'>
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">ACCIONES</th>
                              <th scope="col">FORMA DE PAGO</th>
                              <th scope="col">COMENTARIOS</th>
                              <th scope="col">PAGO</th>
                            </tr>
                          </thead>
                          <tbody>

                            {Pagos.length === 0 ?
                              <>
                                <tr>
                                  <td colSpan={4}>No se han agregado mas de una forma de pago</td>
                                </tr>
                              </> :
                              Pagos.map((item, index) => (
                                <tr key={index} >
                                  <th scope="row">
                                    <button type="button" className="btn btn-outline-danger" onClick={() => handleRemovePago(index)}
                                    //
                                    ><Trash2 size='20' /></button>
                                  </th>
                                  <td>{item.string}</td>
                                  <td>{item.comentarios}</td>
                                  <td>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.total)}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                  <div className="card-body text-end">
                    <button type="button" className="btn btn-outline-danger mt-2" onClick={cancel}>
                      <Slash size='20' /> Cancelar
                    </button>
                    <button type="submit" className="btn btn-outline-primary mt-2 ms-3" onClick={closeOrden}>
                      <Save size='20' /> Guardar
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </>
      )
    }


    // console.log(orden_detail);

    let dat = new Date();
    let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m; let fechaHO = dat.getFullYear() + "-" + m + "-" + d;
    let fechaH = new Date(fechaHO).getTime();

    let fechaR = new Date(orden_detail.FECHA_CERRADA_C).getTime();
    let diferenciadias = (fechaH - fechaR) / (1000 * 60 * 60 * 24);

    // console.log(fechaH);
    // console.log(fechaR);
    // console.log(diferenciadias);


    if (orden_detail.ESTADO == 8 || orden_detail.ESTADO == 9) {
      return (
        <div className='row'>
          <div className='col-12'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item btn-link" onClick={goNewSearch}><ArrowLeft size='20' /> Atras</li>
                <li className="breadcrumb-item active" aria-current="page">Detalles de la Orden</li>
              </ol>
            </nav>
          </div>

          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
            <div className="alert alert-light" role="alert">
              <div className='row'>
                <div className='col-1'>
                  <AlertOctagon size='20' />
                </div>
                <div className='col-10 text-start'>
                  <span className='fw-bold '>La Orden de Servicio con el Folio: {orden_detail.FOLIO}, ya fue
                    {
                      orden_detail.ESTADO == 8 ? <> Concluida por {orden_detail.EMPLEADO_CIERRA} el dia {orden_detail.FECHA_CERRADA} a las {orden_detail.HORA_CERRADA} </> : <></>
                    }
                    {
                      orden_detail.ESTADO == 9 ? <> Cancelada por peticion del Cliente {orden_detail.NOMBRE} el dia {orden_detail.FECHA_CERRADA} a las {orden_detail.HORA_CERRADA}  </> : <></>
                    }

                  </span><br />
                  <button type="button" className="btn btn-outline-dark mt-2 me-3" onClick={goNewSearch}>
                    Ok. Entiendo, vamos atras....
                  </button>
                  <button type="button" className="btn btn-outline-dark mt-2" onClick={() => launchreport(orden_detail.FOLIO)}>
                    <Printer size='20' /> Imprimir reporte
                  </button>

                  {
                    orden_detail.ESTADO == 8 ?
                      <>
                        {
                          diferenciadias <= tarifaAlm.DIAS_GARANTIA ?
                            <>
                              <button type="button" className="btn btn-outline-primary mt-2 me-3">
                                <Shield size='20' /> Solicitud de Garantia
                              </button>
                            </>
                            : <>
                              <p className='mt-2 fw-bold'> No es posible solicitar la garantia porque ya caduco para esta orden</p>
                            </>
                        }
                      </>
                      : <><p className='mt-2 fw-bold'> No es posible solicitar la garantia porque la orden fue cancelada a peticion del cliente</p></>
                  }
                </div>
              </div>
            </div>
          </div>
          {/* <div className='col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-3 text-end'>
          </div> */}

          <div className='col-12'>
            <ViewEvidencias /> <Galeria /> <AddFacturacion /> <SearchFacturacion />
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <div className="card ">
                  <div className="card-body">
                    <label className="mb-1 fw-bold text-dark">Orden de Servicio:</label>
                    <div className="d-flex">
                      <input className="form-control me-2" type="search" aria-label="Search" disabled value={orden_detail.FOLIO} />

                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="card-body">
                    <div className="row mb-1">
                      <div className="col-12">
                        <h5 className="fw-bold me-2 border-0">Datos del Cliente </h5>
                      </div>
                    </div>
                    <hr className="my-0" />
                    <div className="row">
                      <div className="col-12 mt-3">
                        <div className="mb-1">
                          <label className="form-label">Nombre:</label>
                          <input type="text" className="form-control" value={orden_detail.NOMBRE} disabled />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Telefono:</label>
                          <input type="text" className="form-control" value={orden_detail.TELEFONO} disabled />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Celular:</label>
                          <input type="text" className="form-control" value={orden_detail.CELULAR} disabled />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label">Correo electronico:</label>
                          <input type="text" className="form-control" value={orden_detail.EMAIL} disabled />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-12">
                        <h5 className="fw-bold me-2 border-0">Equipo </h5>
                        <hr className="my-0" />
                      </div>
                      <div className="col-12 mt-1">
                        <div className="mb-2">
                          <label className="form-label">Equipo:</label>
                          <input type="text" className="form-control" value={orden_detail.EQUIPO} disabled />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2">
                          <label className="form-label">S.N:</label>
                          <input type="text" className="form-control" value={orden_detail.NO_SERIE} disabled />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2">
                          <label className="form-label">Sistema Operativo:</label>
                          <input type="text" className="form-control" value={orden_detail.SIS_OP} disabled />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Cargador: <span> {orden_detail.CARGADOR == 1 ? <> Si </> : <> No </>} </span>
                          </label>
                          <input type="text" className="form-control" value={orden_detail.CARGADOR_SN} disabled />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Bateria: <span> {orden_detail.BATERIA == 1 ? <> Si </> : <> No </>}</span></label>
                          <input type="text" className="form-control" value={orden_detail.BATERIA_SN} disabled />
                        </div>
                      </div>

                      <div className="col-12 mt-2">
                        <button type="button" className="btn btn-outline-dark border-0 w-100" data-bs-toggle="modal" data-bs-target="#galery">Ver fotografias</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
                <div className="card">

                  <div className="card-body">

                    <h5 className="card-title fw-bold text-dark mb-2">Detalles de trabajo realizado</h5>
                    <hr className="my-0" />
                    {/* dIAGNOSTICO */}
                    <div className="row mt-2">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                        <label className="form-label fw-bold">Descripción de la falla(s):</label>
                        <textarea className="form-control" rows="6" disabled defaultValue={orden_detail.DESCRIPCION_FALLA}></textarea>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                        <label className="form-label fw-bold">Diagnostico:</label>
                        <textarea className="form-control" rows="6" disabled defaultValue={orden_detail.DIAGNOSTICO}></textarea>
                      </div>
                    </div>

                    {/* sERVICIOS */}
                    <h5 className="card-title fw-bold text-dark mb-2 mt-3">Servicios realizados </h5>
                    <div className="row mb-3">
                      <div className="table-responsive mt-1 table-scrollbar-6">
                        <table className="table table-sm table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col" className='th-sticky'>#</th>
                              <th scope="col" className='th-sticky'>SERVICIO</th>
                              <th scope="col" className="text-center th-sticky">AUTORIZADO</th>
                              <th scope="col" className="text-center th-sticky">REALIZADO</th>
                              <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                              <th scope="col" className="text-end th-sticky">COSTO</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              servicios.map((item, index) => (
                                <tr key={index} className={item.AUTORIZO != 1 ? 'table-warning' : ''}>
                                  <td>{index + 1}</td>
                                  <td>{item.NOMBRE}</td>
                                  <td className="text-center">
                                    {
                                      item.AUTORIZO == 1 ? <>
                                        <img src="./assets/images/indicators/check.png" width="28" height="28" /></> : <><img src="./assets/images/indicators/close.png" width="28" height="28" /></>
                                    }
                                  </td>
                                  <td className="text-center" >
                                    {
                                      item.ESTADO == 1 ? <>
                                        <img src="./assets/images/indicators/check.png" width="28" height="28" /></> : <><img src="./assets/images/indicators/close.png" width="28" height="28" /></>
                                    }
                                  </td>
                                  <td className="text-center">
                                    {
                                      evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                    }
                                  </td>
                                  <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO)}</td>

                                </tr>
                              ))
                            }

                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={5} className="text-end fw-bold">Subtotal:</td>
                              <td className="fw-bold text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_servicios)}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    {/* Refacciones Instaladas */}
                    <h5 className="card-title fw-bold text-dark mb-1 mt-4">Refacciones Instaladas </h5>
                    <div className="row mb-3">
                      <div className="table-responsive mt-1 table-scrollbar-6">
                        <table className="table table-sm table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col" className='th-sticky'>#</th>
                              <th scope="col" className='th-sticky'>REFACCIÓN</th>
                              <th scope="col" className="text-center th-sticky">ESTADO</th>
                              <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                              <th scope="col" className="text-end th-sticky">COSTO ($)</th>
                              <th scope="col" className="text-end th-sticky">C. CLIENTE. ($)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              refacciones.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.REFACCION}</td>

                                  <td className="text-center">
                                    {item.ESTADO == 0 ? <> <span className="status-btn pending-btn"> Solicitada </span>  </> : <> </>}
                                    {item.ESTADO == 1 ? <> <span className="status-btn revision-btn"> Aprobada </span>  </> : <> </>}
                                    {item.ESTADO == 2 ? <> <span className="status-btn progress-btn"> Comprada, en espera </span>  </> : <> </>}
                                    {item.ESTADO == 3 ? <> <span className="status-btn tests-btn"> Llego </span>  </> : <> </>}
                                    {item.ESTADO == 4 ? <> <span className="status-btn vacaciones-btn"> Disponible </span>  </> : <> </>}
                                    {item.ESTADO == 5 ? <> <span className="status-btn complete-btn"> Instalada </span>  </> : <> </>}
                                    {item.ESTADO == 6 ? <> <span className="status-btn pending-btn"> Rechazo Cliente </span>  </> : <> </>}
                                  </td>
                                  <td className="text-center">
                                    {
                                      evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                    }
                                  </td>

                                  <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_HDL)}</td>
                                  <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_CLIENTE)}</td>
                                </tr>
                              ))
                            }

                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={5} className="text-end fw-bold">Subtotal:</td>
                              <td className="fw-bold text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_refacciones)}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-12'>
                        <div className="text-end">
                          <h5 className="">Subtotal de Servicios y Refacciones: <span
                            className="fw-bold">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal)}</span> </h5>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }else {

      let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
      let fechaHoy = dat.getFullYear() + "/" + m + "/" + d;
      let serviciosAplicados = orden_detail.SERVICIOS;
      let sGarantiaAplicable = [];
      serviciosAplicados.forEach(item => {


        if (item.DIAS_GARANTIA > 0) {

          

          let fechaCierre = new Date(orden_detail.FECHA_CERRADA_C); // Fecha actual
          let garantiaVence = new Date(fechaCierre);
          garantiaVence.setDate(garantiaVence.getDate() + Number(item.DIAS_GARANTIA)); // Sumar 30 días

          let dia = String(garantiaVence.getDate()).padStart(2, '0'); // Obtener día y rellenar con 0 si es necesario
          let mes = String(garantiaVence.getMonth() + 1).padStart(2, '0'); // Mes (se suma 1 porque empieza en 0)
          let anio = garantiaVence.getFullYear(); // Año completo

          let garantiaVenceFormateada = `${dia}/${mes}/${anio}`;
          let garantiaVenceF = `${anio}/${mes}/${dia}`;

          if (fechaHoy <= garantiaVenceF && item.ESTADO ==1 && item.AUTORIZO==1) {
            sGarantiaAplicable.push(item)
          }
        }
      });

      // console.log(orden_detail.GARANTIA);


      const goWarranties = (folio) => {
        // console.log(folio);
        dispatch(startloadGarantia(folio))
        navigate('/warranties');
      }





      // Control de Fotografias
      const addFotografia = () => {
        // setEvidencias([]);
        let filesA = document.getElementById("fotografia_up").files;
        // let evidencia = Fotografias.filter(item => item.name == filesA[0].name);
        setFotografias([...Fotografias, ...filesA]);
        Toast.fire({
          icon: 'success',
          title: 'Fotografia del equipo agregada al Listado'
        })


      }

      const removeFotografia = (name) => {
        Toast.fire({
          icon: 'success',
          title: 'Fotografia Eliminada de la lista'
        });
        let arrayANTERIOR = [...Fotografias];
        // console.log(arrayANTERIOR);
        let result = arrayANTERIOR.filter(item => item.name != name);
        setFotografias(result);
      }

      const removeAllFotografias = () => {
        if (Fotografias.length != 0) {
          setFotografias([]);
          Toast.fire({
            icon: 'success',
            title: 'Se ha eliminado la lista de fotografias'
          });
        }
      }




      const startRegisterGarantia = (folio) => {

        if (Fotografias.length == 0) {
          Toast.fire({
            icon: 'error',
            title: 'NO se han agregado Fotografias'
          });
          return;
        }

        dispatch(startRegistroGarantia(folio, id_emp, sGarantiaAplicable, orden_detail.CLIENTE_ID, orden_detail.EQUIPO_ID, Fotografias))
      }

      return (
        <div className='row'>
          <div className='col-12'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item btn-link" onClick={goNewSearch}><ArrowLeft size='20' /> Atras</li>
                <li className="breadcrumb-item active" aria-current="page">Detalles de la Orden</li>
              </ol>
            </nav>
          </div>

          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
            <div className="alert alert-light" role="alert">
              <div className='row'>
                <div className='col-1'>
                  <AlertOctagon size='20' />
                </div>
                <div className='col-10 text-start'>
                  <div className='row'>
                    <div className='col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10'>
                      <span className=' '>La Orden de Servicio con el Folio: {orden_detail.FOLIO}, ya fue
                        {
                          orden_detail.ESTADO == 8 ?
                            <>
                              Concluida por {orden_detail.EMPLEADO_CIERRA} el dia {orden_detail.FECHA_CERRADA} a las {orden_detail.HORA_CERRADA}


                              {
                                (orden_detail.GARANTIA != null || orden_detail.GARANTIA != undefined || orden_detail.GARANTIA > 0) ?
                                  <>

                                    <br />
                                    Esta orden tiene registrado una Solicitud de Garantia con el Folio {orden_detail.GARANTIA}


                                  </> :
                                  <>


                                    {
                                      (sGarantiaAplicable.length != 0) ?
                                        <><br />
                                          Esta orden puede ingresar el equipo a garantia por el/los siguiente(s) Servicio(s):

                                          {
                                            sGarantiaAplicable.map((item, index) => (
                                              <>  <br />
                                                <span key={index}> * - Servicio: {item.NOMBRE} Garantia: {item.DIAS_GARANTIA} - * </span>
                                              </>
                                            ))
                                          }

                                          <div className='row mt-2'>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                              <h5 className="card-title fw-bold text-dark mb-2">Fotografias del equipo</h5>
                                              <hr className="my-0 mb-3" />
                                              <div className="d-flex">
                                                <label htmlFor="formFile" className="form-label"></label>
                                                <input className="form-control form-control" id="fotografia_up" type="file" accept="image/*" />
                                                <button className="btn btn-outline-primary no border-0 " type="button" onClick={() => addFotografia()}>
                                                  <Plus size='20' />
                                                </button>
                                              </div>

                                              <div className="mt-2 table-responsive table-scrollbar-5">
                                                <table className="table table-sm">
                                                  <thead>
                                                    <tr>
                                                      <th scope="col">#</th>
                                                      <th scope="col">Archivo</th>
                                                      <th scope="col" className="text-center">ACCIONES</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>

                                                    {
                                                      Fotografias.length === 0 ?
                                                        <>
                                                          <tr >
                                                            <td scope="row" colSpan={3} className='text-danger fw-bold'> <AlertTriangle size={20} className='text-danger fw-bold me-2' /> No se han agregado fotografias, de como se ha recibido el equipo</td>
                                                          </tr>
                                                        </> :
                                                        Fotografias.map((item, index) => (
                                                          <tr key={index}>
                                                            <td scope="row">{index + 1}</td>
                                                            <td>{item.name}</td>
                                                            {/* <td className="text-end">{item.COSTO_SERV}</td> */}
                                                            <td className="text-center">
                                                              <button className="btn btn-outline-danger border-0 " type="button" onClick={() => removeFotografia(item.name)}>
                                                                <Trash2 size='20' />
                                                              </button>
                                                            </td>
                                                          </tr>
                                                        ))
                                                    }
                                                  </tbody>
                                                </table>
                                              </div>
                                              <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                <button type="button" className="btn btn-outline-danger mt-1 mb-1 me-4" onClick={removeAllFotografias} >Eliminar todos: ({Fotografias.length})</button>

                                                <button type="button" className="btn btn-outline-primary mt-1 mb-1  " onClick={() => startRegisterGarantia(orden_detail.FOLIO)}>
                                                  <FilePlus size='20' /> Solicitar Garantia
                                                </button>
                                              </div>
                                            </div>
                                          </div>


                                        </> :
                                        <>
                                          <br />
                                          Esta orden NO puede ingresar el equipo a garantia, estas ya vencieron o no cuenta con garantia los servicios realizados.
                                        </>
                                    }
                                  </>
                              }



                            </> : <></>
                        }
                        {
                          orden_detail.ESTADO == 9 ? <> Cancelada por peticion del Cliente {orden_detail.NOMBRE} el dia {orden_detail.FECHA_CERRADA} a las {orden_detail.HORA_CERRADA}  </> : <></>
                        }

                      </span>
                    </div>

                    <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 '>

                      <button type="button" className="btn btn-outline-dark mt-2 w-100" onClick={() => launchreport(orden_detail.FOLIO)}>
                        <Printer size='20' /> Imprimir reporte
                      </button>

                      {
                        (orden_detail.GARANTIA != null || orden_detail.GARANTIA != undefined || orden_detail.GARANTIA > 0) ?
                          <>
                            <button type="button" className="btn btn-outline-primary mt-2 w-100"
                              onClick={() => goWarranties(orden_detail.GARANTIA)}
                            >
                              <ArrowRightCircle size='20' /> Seguimiento a Garantia
                            </button>
                          </> :
                          <>

                          </>
                      }
                    </div>






                  </div>




                </div>
              </div>
            </div>
          </div>


          {/* Aqui va */}
          <div className='col-12'>
            <ViewEvidencias /> <Galeria />
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <div className="card ">
                  <div className="card-body">
                    <label className="mb-1 fw-bold text-dark">Orden de Servicio:</label>
                    <div className="d-flex">
                      <input className="form-control me-2" type="search" aria-label="Search" disabled value={orden_detail.FOLIO} />

                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="card-body">
                    <div className="row mb-1">
                      <div className="col-12">
                        <h5 className="fw-bold me-2 border-0">Datos del Cliente </h5>
                      </div>
                    </div>
                    <hr className="my-0" />
                    <div className="row">
                      <div className="col-12 mt-3">
                        <div className="mb-1">
                          <label className="form-label">Nombre:</label>
                          <input type="text" className="form-control" value={orden_detail.NOMBRE} disabled />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Telefono:</label>
                          <input type="text" className="form-control" value={orden_detail.TELEFONO} disabled />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Celular:</label>
                          <input type="text" className="form-control" value={orden_detail.CELULAR} disabled />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label">Correo electronico:</label>
                          <input type="text" className="form-control" value={orden_detail.EMAIL} disabled />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-12">
                        <h5 className="fw-bold me-2 border-0">Equipo </h5>
                        <hr className="my-0" />
                      </div>
                      <div className="col-12 mt-1">
                        <div className="mb-2">
                          <label className="form-label">Equipo:</label>
                          <input type="text" className="form-control" value={orden_detail.EQUIPO} disabled />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2">
                          <label className="form-label">S.N:</label>
                          <input type="text" className="form-control" value={orden_detail.NO_SERIE} disabled />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2">
                          <label className="form-label">Sistema Operativo:</label>
                          <input type="text" className="form-control" value={orden_detail.SIS_OP} disabled />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Cargador: <span> {orden_detail.CARGADOR == 1 ? <> Si </> : <> No </>} </span>
                          </label>
                          <input type="text" className="form-control" value={orden_detail.CARGADOR_SN} disabled />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Bateria: <span> {orden_detail.BATERIA == 1 ? <> Si </> : <> No </>}</span></label>
                          <input type="text" className="form-control" value={orden_detail.BATERIA_SN} disabled />
                        </div>
                      </div>

                      <div className="col-12 mt-2">
                        <button type="button" className="btn btn-outline-dark border-0 w-100" data-bs-toggle="modal" data-bs-target="#galery">Ver fotografias</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
                <div className="card">

                  <div className="card-body">

                    <h5 className="card-title fw-bold text-dark mb-2">Detalles de trabajo realizado</h5>
                    <hr className="my-0" />
                    {/* dIAGNOSTICO */}
                    <div className="row mt-2">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                        <label className="form-label fw-bold">Descripción de la falla(s):</label>
                        <textarea className="form-control" rows="6" disabled defaultValue={orden_detail.DESCRIPCION_FALLA}></textarea>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                        <label className="form-label fw-bold">Diagnostico:</label>
                        <textarea className="form-control" rows="6" disabled defaultValue={orden_detail.DIAGNOSTICO}></textarea>
                      </div>
                    </div>

                    {/* sERVICIOS */}
                    <h5 className="card-title fw-bold text-dark mb-2 mt-3">Servicios realizados </h5>
                    <div className="row mb-3">
                      <div className="table-responsive mt-1 table-scrollbar-6">
                        <table className="table table-sm table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col" className='th-sticky'>#</th>
                              <th scope="col" className='th-sticky'>SERVICIO</th>
                              <th scope="col" className="text-center th-sticky">GARANTIA</th>
                              <th scope="col" className="text-center th-sticky">AUTORIZADO</th>
                              <th scope="col" className="text-center th-sticky">REALIZADO</th>
                              <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                              <th scope="col" className="text-end th-sticky">COSTO</th>

                            </tr>
                          </thead>
                          <tbody>
                            {
                              servicios.map((item, index) => (
                                <tr key={index} className={item.AUTORIZO != 1 ? 'table-warning' : ''}>
                                  <td>{index + 1}</td>
                                  <td>{item.NOMBRE}</td>
                                  <td className="text-center">{item.DIAS_GARANTIA} Dia(s) </td>
                                  <td className="text-center">
                                    {
                                      item.AUTORIZO == 1 ? <>
                                        <img src="./assets/images/indicators/check.png" width="28" height="28" /></> : <><img src="./assets/images/indicators/close.png" width="28" height="28" /></>
                                    }
                                  </td>
                                  <td className="text-center" >
                                    {
                                      item.ESTADO == 1 ? <>
                                        <img src="./assets/images/indicators/check.png" width="28" height="28" /></> : <><img src="./assets/images/indicators/close.png" width="28" height="28" /></>
                                    }
                                  </td>
                                  <td className="text-center">
                                    {
                                      evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                    }
                                  </td>
                                  <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO)}</td>


                                </tr>
                              ))
                            }

                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={5} className="text-end fw-bold">Subtotal:</td>
                              <td className="fw-bold text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_servicios)}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    {/* Refacciones Instaladas */}
                    <h5 className="card-title fw-bold text-dark mb-1 mt-4">Refacciones Instaladas </h5>
                    <div className="row mb-3">
                      <div className="table-responsive mt-1 table-scrollbar-6">
                        <table className="table table-sm table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col" className='th-sticky'>#</th>
                              <th scope="col" className='th-sticky'>REFACCIÓN</th>
                              <th scope="col" className="text-center th-sticky">ESTADO</th>
                              <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                              <th scope="col" className="text-end th-sticky">COSTO ($)</th>
                              <th scope="col" className="text-end th-sticky">C. CLIENTE. ($)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              refacciones.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.REFACCION}</td>

                                  <td className="text-center">
                                    {item.ESTADO == 0 ? <> <span className="status-btn pending-btn"> Solicitada </span>  </> : <> </>}
                                    {item.ESTADO == 1 ? <> <span className="status-btn revision-btn"> Aprobada </span>  </> : <> </>}
                                    {item.ESTADO == 2 ? <> <span className="status-btn progress-btn"> Comprada, en espera </span>  </> : <> </>}
                                    {item.ESTADO == 3 ? <> <span className="status-btn tests-btn"> Llego </span>  </> : <> </>}
                                    {item.ESTADO == 4 ? <> <span className="status-btn vacaciones-btn"> Disponible </span>  </> : <> </>}
                                    {item.ESTADO == 5 ? <> <span className="status-btn complete-btn"> Instalada </span>  </> : <> </>}
                                    {item.ESTADO == 6 ? <> <span className="status-btn pending-btn"> Rechazo Cliente </span>  </> : <> </>}
                                  </td>
                                  <td className="text-center">
                                    {
                                      evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                    }
                                  </td>

                                  <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_HDL)}</td>
                                  <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_CLIENTE)}</td>
                                </tr>
                              ))
                            }

                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={5} className="text-end fw-bold">Subtotal:</td>
                              <td className="fw-bold text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_refacciones)}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-12'>
                        <div className="text-end">
                          <h5 className="">Subtotal de Servicios y Refacciones: <span
                            className="fw-bold">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal)}</span> </h5>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

  }

}
