import { URL_API } from "../../apiConnection";
import Swal from 'sweetalert2';
import { processAction, setError,clearStore, setFacturacion, setSumaryOrders, setAccounts, setPerformance, setFacturacion2, setPostOrder, setlistServices, setPostServices } from "./slice";

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});


export const clearStoreReport = () => {
    return (dispatch, getState) => {
        dispatch(clearStore());
    }
}


export const startloadSolFacturacion = () => {
    return async (dispatch, getSate) => {

        dispatch(processAction());
        await URL_API.get(`Reportes/getSolFacturacion`).then(function (response) {
            // console.log(response.data.data);
            const items = response.data.data;
            if (response.data.status == 200) {
                dispatch(setFacturacion({ facturacion: items }))
            }
        }).catch(function (error) {
            // console.log(error.response.data.msg);
            dispatch(setError({ msg: error.response.data.msg }))
        });
    }
}

export const startloadAccounts = () => {
    return async (dispatch, getSate) => {

        dispatch(processAction());
        await URL_API.get(`Reportes/getAccountsReceivable`).then(function (response) {
            // console.log(response.data.data);
            const items = response.data.data;
            if (response.data.status == 200) {
                dispatch(setAccounts({ accountsReceivable: items }))
            }
        }).catch(function (error) {
            // console.log(error.response.data.msg);
            dispatch(setError({ msg: error.response.data.msg }))
        });
    }
}


export const startChangeStateFacturacion = (tipo, folio, estado) => {
    return async (dispatch, getState) => {
        await URL_API.put(`Reportes/putChangeFacturacion/${tipo}/${folio}/${estado}`).then(function (response) {
            if (response.data.status == 200) {
                dispatch(startloadSolFacturacion());
                Toast.fire({
                    icon: 'success',
                    title: 'Estado de Solicitud de Facturacion Completa'
                })
                dispatch(startloadSolFacturacion())
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible Actualizar el estado de Solicitud de Facturacion',
                });
                dispatch(startloadSolFacturacion())
            }
        }).catch(function (error) {
            console.log(error);
            dispatch(startloadSolFacturacion())

        });
    }
}


export const startloadSumaryOrders = (fechaI, fechaF,opcionReporte) => {
    return async (dispatch, getSate) => {

        dispatch(processAction());
        await URL_API.get(`Reportes/getReportSumaryOrders/${fechaI}/${fechaF}/${opcionReporte}`).then(function (response) {
            // console.log(response.data.data);
            const items = response.data.data;
            if (response.data.status == 200) {
                dispatch(setSumaryOrders({ sumaryOrders: items[0] }))
            }
        }).catch(function (error) {
            // console.log(error.response.data.msg);
            dispatch(setError({ msg: error.response.data.msg }))
        });
    }
}


export const startloadPerformance = (fechaI, fechaF,emp) => {
    return async (dispatch, getSate) => {

        dispatch(processAction());
        await URL_API.get(`Reportes/getReportPerformance/${fechaI}/${fechaF}/${emp}`).then(function (response) {
            console.log(response.data.totales);
            const items = response.data.data;
            if (response.data.status == 200) {
                dispatch(setPerformance({ performance: items, performanceTotales:response.data.totales  }))
            }
        }).catch(function (error) {
            // console.log(error.response.data.msg);
            dispatch(setError({ msg: error.response.data.msg }))
        });
    }
}


export const startloadReportFacturacion2 = (fechaI, fechaF,formaPago,opcionReporte) => {
    return async (dispatch, getSate) => {

        dispatch(processAction());
        await URL_API.get(`Reportes/getReportFacturacion/${fechaI}/${fechaF}/${formaPago}/${opcionReporte}`).then(function (response) {
            // console.log(response.data.data);
            const items = response.data.data;
            if (response.data.status == 200) {
                dispatch(setFacturacion2({ facturacion2: items }))
            }
        }).catch(function (error) {
            // console.log(error.response.data.msg);
            dispatch(setError({ msg: error.response.data.msg }))
        });
    }
}

export const startloadReportPostOrders = (fechaI, fechaF) => {
    return async (dispatch, getSate) => {

        dispatch(processAction());
        await URL_API.get(`Reportes/getReportPostSale/${fechaI}/${fechaF}/`).then(function (response) {
            // console.log(response.data.totales);
            const items = response.data.data;
            if (response.data.status == 200) {
                dispatch(setPostOrder({ postOrders: items  }))
            }
        }).catch(function (error) {
            // console.log(error.response.data.msg);
            dispatch(setError({ msg: error.response.data.msg }))
        });
    }
}

export const startloadListServices = (fechaI, fechaF) => {
    return async (dispatch, getSate) => {

        dispatch(processAction());
        await URL_API.get(`Reportes/getServicesForPeriod/${fechaI}/${fechaF}`).then(function (response) {
            // console.log(response.data.totales);
            const items = response.data.data;
            if (response.data.status == 200) {
                dispatch(setlistServices({ listServices: items  }))
            }
        }).catch(function (error) {
            // console.log(error.response.data.msg);
            dispatch(setError({ msg: error.response.data.msg }))
        });
    }
}

export const startloadReportPostServices = (fechaI, fechaF,servicio) => {
    return async (dispatch, getSate) => {
        dispatch(processAction());
        await URL_API.get(`Reportes/getServicesFulForPeriod/${fechaI}/${fechaF}/${servicio}`).then(function (response) {
            // console.log(response.data.totales);
            const items = response.data.data;
            if (response.data.status == 200) {
                dispatch(setPostServices({ postServices: items  }))
            }
        }).catch(function (error) {
            // console.log(error.response.data.msg);
            dispatch(setError({ msg: error.response.data.msg }))
        });
    }
}
