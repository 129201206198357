import { createSlice } from '@reduxjs/toolkit';

export const ReportSlice = createSlice({
    name: 'Reports',
    initialState: {
        status_report: 'EN_ESPERA',
        facturacion: [],
        sumaryOrders: [],
        accountsReceivable: [],
        performance:[],
        performanceTotales:[],
        facturacion2:[],

        postOrders: [],
        listServices: [],
        postServices: [],

        isProcessAction: false,
        isLoading: false,
    },
    reducers: {
        clearStore: (state, action) => {
            state.status_report = 'EN_ESPERA';
            state.facturacion = [];
            state.facturacion2 = [];
            state.sumaryOrders = [];
            state.accountsReceivable = [];
            state.performance = [];
            state.performanceTotales = [];
            state.postOrders = [];
            // state.listServices = [];
            state.postServices = [];
            // state.indicadores = [];
            // state.refacciones = [];w
            // state.facturacion_edit = [];
        },
        processAction: (state, action) => {
            state.isLoading = true;
            state.status_report = 'PROCESANDO_PETICION';
            state.facturacion = [];
            state.facturacion2 = [];
            state.sumaryOrders = [];
            state.accountsReceivable = [];
            state.performance = [];
            state.performanceTotales = [];
            state.postOrders = [];
            // state.listServices = [];
            state.postServices = [];
        },
        notprocessAction: (state, action) => {
            state.isLoading = false;
            state.status_report = 'EN_ESPERA';
        },
        setFacturacion: (state, action) => {
            state.isLoading = false;
            state.status_report = "SOLICITUDES_FACTURACION_CARGADAS";
            state.facturacion = action.payload.facturacion;
        },

        setFacturacion2: (state, action) => {
            state.isLoading = false;
            state.status_report = "REPORTE_FACTURACION_CARGADO";
            state.facturacion2 = action.payload.facturacion2;
        },
        setSumaryOrders: (state, action) => {
            state.isLoading = false;
            state.status_report = "ORDENES_CARGADAS";
            state.sumaryOrders = action.payload.sumaryOrders;
        },
        setAccounts: (state, action) => {
            state.isLoading = false;
            state.status_report = "CUENTAS_CARGADAS";
            state.accountsReceivable = action.payload.accountsReceivable;
        },
        setPerformance: (state, action) => {
            state.isLoading = false;
            state.status_report = "REPORTE_RENDIMIENTO_CARGADO";
            state.performance = action.payload.performance;
            state.performanceTotales = action.payload.performanceTotales;
        },
        setPostOrder: (state, action) => {
            state.isLoading = false;
            state.status_report = "REPORTE_POSTORDERS_CARGADO";
            state.postOrders = action.payload.postOrders;
        },
        
        setlistServices: (state, action) => {
            state.isLoading = false;
            state.status_report = "LISTADO_SERVICIOS_CARGADO";
            state.listServices = action.payload.listServices;
        },
        setPostServices: (state, action) => {
            state.isLoading = false;
            state.status_report = "REPORTE_POSTSERVICES_CARGADO";
            state.postServices = action.payload.postServices;
        },
        
        setError:(state,action)=>{
            state.isLoading=false;
            state.status_report=action.payload.msg;
            state.facturacion =[];
        }

       
    }
});


// Action creators are generated for each case reducer function
export const { processAction,notprocessAction,setFacturacion,setPostOrder,setError,setlistServices,setPostServices,setSumaryOrders,setAccounts,setPerformance,clearStore,setFacturacion2} = ReportSlice.actions;