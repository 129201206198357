import React from 'react'
import { Table_2 } from '../../../utils/Table_2'
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AlertTriangle, Search } from 'react-feather';
import { startloadListServices, startloadReportPostServices } from '../../../../app/store/reportes/thunks';
import Select from 'react-select';
import { startloadSaleReport } from '../../../../app/store/sales/thunks';
import { startloadOrden } from '../../../../app/store/ordenes/thunks';

export const PostService = () => {

  const { isLoading, status_report, listServices, postServices } = useSelector(state => state.reportes);
  const { register, control, handleSubmit, watch, reset, getValues, setValue, formState: { errors } } = useForm({ model: 'all' });
  const dispatch = useDispatch();


  const changedDate = () => {
    let dateI = watch("fechaInicial");
    let dateF = watch("fechaFinal");
    dispatch(startloadListServices(dateI, dateF))
  }


  const searchOrdenes = (data) => {
    console.log(data);

    // console.log();
    // data.servicio

    dispatch(startloadReportPostServices(data.fechaInicial, data.fechaFinal,data.servicio_realizar.value ))
  }


  const optionsSelect = listServices?.map((item) => ({
    value: item.ID,
    label: `${item.DESCRIPCION.toUpperCase()}`
  }));


  const dataU = [...postServices];
  const columnsType1 = [
    {
      header: 'FOLIO',
      dataIndex: 'FOLIO',
      classHeader: 'text-center',
      classRow: 'text-center fw-bold text-primary',
      renderer: (value) => (
        <>
          <button type="button" className="btn btn-link" data-bs-toggle="modal" data-bs-target="#detailFolio" onClick={() => loadFolio(value)}>{value}</button>
        </>
      ),
    },
    {
      header: 'FECHA CIERRE',
      dataIndex: 'FECHA_CERRADA',
      classHeader: 'text-center',
      classRow: 'text-center',
    },
    {
      header: 'CLIENTE',
      dataIndex: 'CLIENTE',
      classHeader: 'text-start',
      classRow: 'text-start',
    },
    {
      header: 'CELULAR',
      dataIndex: 'CELULAR_CLIENTE',
      classHeader: 'text-center',
      classRow: 'text-center',
    },
    {
      header: 'TELEFONO',
      dataIndex: 'TELEFONO_CLIENTE',
      classHeader: 'text-center',
      classRow: 'text-center',
    },
    {
      header: 'EMAIL',
      dataIndex: 'EMAIL_CLIENTE',
      classHeader: 'text-start',
      classRow: 'text-start',
    },
  ];


  const loadFolio = (folio) => {

    if (folio.includes("HDLV")) {
      dispatch(startloadSaleReport(folio))
    } else {
      dispatch(startloadOrden(folio));
    }

  }
  return (
    <div>
      <h2 className="h4  fw-bold">Seguimiento Post Orden de Servicio</h2>
      <div>
        <div className="card-body">

          <form className='row' onSubmit={handleSubmit(searchOrdenes)} >
            <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <label className="form-label">Fecha de Inicio:</label>
              <input type="date" className="form-control" {...register("fechaInicial", { required: 'Introduce fecha de inicio' })} />
              {
                errors.fechaInicial &&
                <div className='text-left-5 mt-2'>
                  <AlertTriangle size="18" color="#dc3545" />
                  <span className='text-left-10 fw-bold text-danger'><small>{errors.fechaInicial.message}</small></span>
                </div>
              }
            </div>
            <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2  ">
              <label className="form-label">Fecha de Termino:</label>
              <input type="date" className="form-control"  {...register("fechaFinal")} onBlur={changedDate} />
            </div>

            <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4  ">
              <label className="form-label">Servicios disponibles:</label>

              <Controller
                name="servicio_realizar"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={optionsSelect}
                    placeholder="Selecciona / Busca un servicio"
                    // placeholder="Selecciona / Busca un servicio"
                    // onChange={(selectedOption) => console.log('Selected:', selectedOption)}
                    noOptionsMessage={() => "No se han encontraron resultados"}
                    isClearable
                  />
                )}
              />

              {/* <select className="form-select "  {...register("servicio")}>
                <option value={'TODOS'} >Todos los servicios</option>
                {
                  listServices?.map((item, index) => (
                    <option key={index} value={item.ID} className='text-uppercase' >({item.DESCRIPCION}) </option>
                
                  ))
                }

              </select> */}


            </div>

            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-1">
              <div className="mb-3 mt-4">
                <button type="submit" className="btn btn-outline-secondary">
                  <Search size="20" /> Buscar
                </button>
              </div>
            </div>
          </form>
          <hr className='my-0' />
          <h5 className='fw-bold mt-4 mb-2'>Resultados</h5>
          <Table_2 typeTable={3} columns={columnsType1} data={dataU} msg={'En espera para mostrar datos'} />
        </div>
      </div>
    </div>
  )
}
