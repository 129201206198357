import React, { useEffect } from 'react'
import { AlertTriangle, DollarSign, Search } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { clearStoreReport, startloadReportFacturacion2 } from '../../../../app/store/reportes/thunks';
import { RiFileExcel2Line } from 'react-icons/ri';
import { startloadSaleReport } from '../../../../app/store/sales/thunks';
import { startloadOrden } from '../../../../app/store/ordenes/thunks';
import { MDetail } from '../../reportes/modals/MDetail';
import * as XLSX from 'xlsx/xlsx.mjs';

export const Report = () => {

  const { descuentos, status, tarifaAlm, tipos_pagos } = useSelector(state => state.catalogos);
  const { isLoading, status_report, facturacion2 } = useSelector(state => state.reportes);

  const { register, handleSubmit, watch, reset, getValues, setValue, formState: { errors } } = useForm({ model: 'all' });
  let f = new Date(); let dia = f.getDate(); let mes = f.getMonth() + 1; if (dia < 10) dia = "0" + dia; if (mes < 10) mes = "0" + mes; let today = f.getFullYear() + "-" + mes + "-" + dia;

  const dispatch = useDispatch();

  const searchOrdenes = (data) => {
    // console.log(data);

    dispatch(startloadReportFacturacion2(data.fechaInicial, data.fechaFinal, data.formaPago, data.opcionReporte))
  }


  const loadFolio = (folio) => {

    if (folio.includes("HDLV")) {
      // console.log("La cadena no contiene 'bienvenido'");
      dispatch(startloadSaleReport(folio))
    } else {

      dispatch(startloadOrden(folio));
    }

  }




  useEffect(() => {
    dispatch(clearStoreReport())
  }, []);

  // console.log(facturacion2);

  let sumTotal = 0;
  facturacion2?.forEach(item => {

    sumTotal = sumTotal + Number(item.MONTO);

  });


  const exportToXLS = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `FACTURACION.xlsx`);
  }

  // console.log(sumTotal);


  return (
    <div className='mt-3'>
      <MDetail />
      <h2 className="h4 mb-3 fw-bold">Reporte de Facturacion</h2>
      <div>


        <div className="card-body">
          <form className='row' onSubmit={handleSubmit(searchOrdenes)} >
            <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
              <label className="form-label">Fecha de Inicio:</label>
              <input type="date" className="form-control" {...register("fechaInicial", { required: 'Introduce fecha de inicio' })} />
              {
                errors.fechaInicial &&
                <div className='text-left-5 mt-2'>
                  <AlertTriangle size="18" color="#dc3545" />
                  <span className='text-left-10 fw-bold text-danger'><small>{errors.fechaInicial.message}</small></span>
                </div>
              }
            </div>
            <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2  ">
              <label className="form-label">Fecha de Termino:</label>
              <input type="date" className="form-control" defaultValue={today} {...register("fechaFinal")} />
            </div>
            <div className='col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2 col-xxl-2'>
              <label className="mb-1 fw-bold text-dark">Forma de Pago</label>
              <select className="form-select " id='formaPago_orden' {...register("formaPago")} >
                <option value={0} className='text-uppercase' >TODAS</option>
                {
                  tipos_pagos.map((item, index) => (
                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                      : <option key={index} style={{ display: "none" }} ></option>
                  ))
                }
              </select>
            </div>

            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-1">
              <div className="mb-3 mt-4">
                <button type="submit" className="btn btn-outline-secondary">
                  <Search size="20" /> Buscar
                </button>
              </div>
            </div>

            <div className='d-flex'>
              <div className="form-check">
                <input className="form-check-input" type="radio"  {...register("opcionReporte")} value="0" defaultChecked />
                <label className="form-check-label" >
                  Sin facturacion
                </label>
              </div>
              <div className="form-check ms-3 ">
                <input className="form-check-input" type="radio"  {...register("opcionReporte")} value="1" />
                <label className="form-check-label" >
                  Con facturacion
                </label>
              </div>

            </div>
          </form>
          <hr className='my-0' />

          <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
            <div className="card mt-3">
              <div className="card-body">
                <div className="row">
                  <div className="col mt-0">
                    <h5 className="card-title text-dark">Ganacias </h5>
                  </div>
                  <div className="col-auto">
                    <div className="stat text-primary">
                      <DollarSign size='20' />
                    </div>
                  </div>
                </div>
                <div className='text-center'>
                  <h1 className="mt-1 mb-3">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(sumTotal)}</h1>



                </div>

              </div>
            </div>
          </div>

          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
            {
              (isLoading) ?
                <>
                  <div className='div_center'>
                    <div className='text-center'>
                      <div className="spinner"></div>
                    </div>
                    <div className='text-center text-left-15'>
                      <span className='fw-bold'>Cargando</span>
                    </div>
                    <div className='text-center text-left-15'>
                      <div className="spinner"></div>
                    </div>
                  </div>
                </> :
                <>
                  <div className='d-flex justify-content-between flex-md-nowrap align-items-center pt-3 pb-1 mb-1 border-bottom'>
                    <h5 className='fw-bold'>Listado</h5>

                    <button type="button" className="btn btn-outline-success border-0 mt-1 fw-bold"
                      onClick={() => exportToXLS(facturacion2)}
                    >
                      <RiFileExcel2Line size={25} /> <span className='text-left-5 align-middle'> Exportar </span>
                    </button>

                  </div>
                  <div className="table-responsive my-0 table-scrollbar_esp_reporte mt-3">



                    <table className="table table-striped table-hover table-sm">
                      <thead>
                        <tr>
                          <th scope="col" className="text-center th-sticky">#</th>
                          <th scope="col" className="text-center th-sticky">FOLIO</th>
                          <th scope="col" className="text-center th-sticky">FECHA</th>
                          <th scope="col" className="text-center th-sticky">FORMA DE PAGO</th>
                          <th scope="col" className="text-end th-sticky">MONTO ($)</th>



                        </tr>
                      </thead>
                      <tbody className="">
                        {
                          facturacion2?.length == 0 ?
                            <>
                              <tr>
                                <td colSpan={5}>No hay datos por mostrar</td>
                              </tr>
                            </> :
                            facturacion2?.map((item, index) => (
                              <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td className="text-center">
                                  <button type="button" className="btn btn-link" data-bs-toggle="modal" data-bs-target="#detailFolio" onClick={() => loadFolio(item.DETALLE)}>{item.DETALLE}</button>
                                </td>
                                <td className="text-center">{item.FECHA_INGRESO}</td>
                                <td className="text-center">{item.FORMA_PAGO_DESC}</td>
                                <td className="text-end"> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.MONTO)}</td>




                              </tr>
                            ))
                        }
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={4} className='fw-bold'>Totales</td>

                          <td colSpan={1} className='fw-bold text-end'>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(sumTotal)}</td>
                        </tr>
                      </tfoot>
                    </table>



                  </div>
                </>
            }
          </div>


















        </div>

      </div>
    </div>
  )
}
