import React from 'react'
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { AlertTriangle, ArrowLeft, Plus, Save, Slash } from 'react-feather'
import * as XLSX from 'xlsx/xlsx.mjs';
import { RiFileExcel2Line } from "react-icons/ri";

export const Table_2 = ({ typeTable, columns, data, msg, function_ }) => {

    // Funciones para paginacion
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(20);
    const [sortConfig, setSortConfig] = useState(null);
    // const perPage = mostrar;

    // Search component
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (event) => {
        // console.log(event.target.value);
        setSearchTerm(event.target.value);
        setCurrentPage(0);
    };

    const sortedData = data ? [...data].sort((a, b) => {
        if (!sortConfig) {
            return 0;
        }

        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];

        if (!isNaN(aValue) && !isNaN(bValue)) {
            return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
        }

        if (aValue < bValue) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }

        return 0;
    }) : [];

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // const filteredData = data ? data.filter(item =>
    const filteredData = sortedData ? sortedData.filter(item =>
        Object.values(item).some(value =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    ) : [];

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const handlePerPageChange = (event) => {
        setPerPage(Number(event.target.value));
        setCurrentPage(0);
    };

    const offset = currentPage * perPage;
    const currentPageItems = filteredData.slice(offset, offset + perPage);

    const exportToXLS = (columns, data) => {
        const d = new Date();
        let h = d.getHours(); let mi = d.getMinutes(); let s = d.getSeconds();
        let hour = h + '.' + mi + '.' + s;
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `ExportData${hour}.xlsx`);
    }

    if (typeTable == 1) {
        return (
            <>
                {(sortedData.length > 0) ?
                    <> <div className='row'>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                            <div className='mb-1'>
                                <label className="form-label me-2 align-middle">Buscar:</label>
                                <input type="search" className='form-control' value={searchTerm} onChange={handleSearch} autoComplete='off' placeholder="Introduce Nombre / Telefono" />
                            </div>
                        </div>
                        <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 col-xxl-2 '>
                            <label className="form-label">Mostrar:</label>
                            <select id="perPageSelect" value={perPage} onChange={handlePerPageChange} className='form-select '>
                                {/* <option value={10}>10</option> */}
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                                <option value={60}>60</option>
                                <option value={70}>70</option>
                                <option value={80}>80</option>
                                <option value={90}>90</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <div className='col-3 col-sm-3 col-md-2 col-lg-2 col-xl-4 col-xxl-4 text-end'>
                            <div className='mt-4'>
                                <button type="button" className="btn btn-outline-success border-0 mt-1 fw-bold" onClick={() => exportToXLS(columns, data)}>
                                    <RiFileExcel2Line size={25} /><span className='text-left-5 align-middle'> Exportar </span>
                                </button>
                            </div>
                        </div>

                    </div>

                        <div className='table-responsive table-scrollbar_esp mb-4'>
                            <table className="table table-sm table-striped table-hover">
                                <thead>
                                    <tr className='th-sticky' >
                                        {columns.map((prop, index) => (
                                            <th key={index} scope="col" className={prop.classHeader} onClick={() => requestSort(prop.dataIndex)}>
                                                {prop.header}
                                                {sortConfig && sortConfig.key === prop.dataIndex && (
                                                    <span>{sortConfig.direction === 'asc' ? ' ▲' : ' ▼'}</span>
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentPageItems.length == 0 ? <>
                                            <tr>
                                                <td colSpan={columns.length}>No se encontraron clientes</td>
                                            </tr>
                                        </> :
                                            currentPageItems.map((row, index) => (
                                                <tr key={index} style={{ cursor: 'pointer' }}
                                                    onDoubleClick={() => function_(row)}
                                                >
                                                    {
                                                        columns.map((column, i) => (
                                                            <td key={i} className={column.classRow}>
                                                                {
                                                                    column.renderer ? column.renderer(row[column.dataIndex], row) : row[column.dataIndex]
                                                                }
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <ReactPaginate
                            containerClassName="pagination pagination-sm"
                            pageClassName="page-item" pageLinkClassName="page-link"
                            activeClassName={'active'} previousClassName="page-item"
                            previousLinkClassName="page-link"
                            previousLabel={'<'} nextLabel={'>'}
                            nextClassName="page-item" nextLinkClassName="page-link"
                            breakLabel={'...'} breakClassName={'break-me'}
                            pageCount={Math.ceil(sortedData.length / perPage)}
                            marginPagesDisplayed={5} pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                        />
                    </> :
                    <>
                        {
                            (msg == "") ?
                                <>
                                    <div className="alert alert-light fw-bold border-primary text-primary mt-3 mb-5" role="alert">
                                        <AlertTriangle size="18" color="#dc3545" />
                                        <span className='text-left-10'>Favor de ingresar parámetros de búsqueda y dar clic en el botón Buscar de arriba</span>
                                    </div>
                                </> :
                                <>
                                    <div className="alert alert-warning fw-bold mt-3 mb-5" role="alert">
                                        <AlertTriangle size="18" color="#664d03" />
                                        <span className='text-left-10'>{msg}</span>
                                    </div>
                                </>
                        }

                    </>
                }
            </>
        )
    }

    if(typeTable==2){
        return (
            <>
                {(sortedData.length > 0) ?
                    <> <div className='row'>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                            <div className='mb-1'>
                                <label className="form-label me-2 align-middle">Buscar:</label>
                                <input type="search" className='form-control' value={searchTerm} onChange={handleSearch} autoComplete='off' placeholder="Introduce Nombre / Telefono" />
                            </div>
                        </div>
                        <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 col-xxl-2 '>
                            <label className="form-label">Mostrar:</label>
                            <select id="perPageSelect" value={perPage} onChange={handlePerPageChange} className='form-select '>
                                {/* <option value={10}>10</option> */}
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                                <option value={60}>60</option>
                                <option value={70}>70</option>
                                <option value={80}>80</option>
                                <option value={90}>90</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <div className='col-3 col-sm-3 col-md-2 col-lg-2 col-xl-4 col-xxl-4 text-end'>
                            <div className='mt-4'>
                                <button type="button" className="btn btn-outline-success border-0 mt-1 fw-bold" onClick={() => exportToXLS(columns, data)}>
                                    <RiFileExcel2Line size={25} /><span className='text-left-5 align-middle'> Exportar </span>
                                </button>
                            </div>
                        </div>

                    </div>

                        <div className='table-responsive table-scrollbar_esp mb-4'>
                            <table className="table table-sm table-striped table-hover">
                                <thead>
                                    <tr className='th-sticky' >
                                        {columns.map((prop, index) => (
                                            <th key={index} scope="col" className={prop.classHeader} onClick={() => requestSort(prop.dataIndex)}>
                                                {prop.header}
                                                {sortConfig && sortConfig.key === prop.dataIndex && (
                                                    <span>{sortConfig.direction === 'asc' ? ' ▲' : ' ▼'}</span>
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentPageItems.length == 0 ? <>
                                            <tr>
                                                <td colSpan={columns.length}>No se encontraron clientes</td>
                                            </tr>
                                        </> :
                                            currentPageItems.map((row, index) => (
                                                <tr key={index} style={{ cursor: 'pointer' }}
                                                    onDoubleClick={() => function_(row)}
                                                >
                                                    {
                                                        columns.map((column, i) => (
                                                            <td key={i} className={column.classRow}>
                                                                {
                                                                    column.renderer ? column.renderer(row[column.dataIndex], row) : row[column.dataIndex]
                                                                }
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <ReactPaginate
                            containerClassName="pagination pagination-sm"
                            pageClassName="page-item" pageLinkClassName="page-link"
                            activeClassName={'active'} previousClassName="page-item"
                            previousLinkClassName="page-link"
                            previousLabel={'<'} nextLabel={'>'}
                            nextClassName="page-item" nextLinkClassName="page-link"
                            breakLabel={'...'} breakClassName={'break-me'}
                            pageCount={Math.ceil(sortedData.length / perPage)}
                            marginPagesDisplayed={5} pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                        />
                    </> :
                    <>
                        {
                            (msg == "") ?
                                <>
                                    <div className="alert alert-light fw-bold border-primary text-primary mt-3 mb-5" role="alert">
                                        <AlertTriangle size="18" color="#dc3545" />
                                        <span className='text-left-10'>Favor de ingresar parámetros de búsqueda y dar clic en el botón Buscar de arriba</span>
                                    </div>
                                </> :
                                <>
                                    <div className="alert alert-warning fw-bold mt-3 mb-5" role="alert">
                                        <AlertTriangle size="18" color="#664d03" />
                                        <span className='text-left-10'>{msg}</span>
                                    </div>
                                </>
                        }

                    </>
                }
            </>
        )
    }

    if (typeTable==3) {
        return (
            <>
                {(sortedData.length > 0) ?
                    <> <div className='row'>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-3">
                            <div className='mb-1'>
                                <label className="form-label me-2 align-middle">Buscar:</label>
                                <input type="search" className='form-control' value={searchTerm} onChange={handleSearch} autoComplete='off' placeholder="Introduce No. de Orden / Nombre Empleado o Cliente" />
                            </div>
                        </div>
                        <div className='col-3 col-sm-3 col-md-3 col-lg-3 col-xl-2 col-xxl-2 '>
                            <label className="form-label">Mostrar:</label>
                            <select id="perPageSelect" value={perPage} onChange={handlePerPageChange} className='form-select '>
                                {/* <option value={10}>10</option> */}
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                                <option value={60}>60</option>
                                <option value={70}>70</option>
                                <option value={80}>80</option>
                                <option value={90}>90</option>
                                <option value={100}>100</option>
                            </select>
                        </div>
                        <div className='col-3 col-sm-3 col-md-2 col-lg-2 col-xl-4 col-xxl-4 text-end'>
                            <div className='mt-4'>
                                <button type="button" className="btn btn-outline-success border-0 mt-1 fw-bold" onClick={() => exportToXLS(columns, data)}>
                                    <RiFileExcel2Line size={25} /><span className='text-left-5 align-middle'> Exportar </span>
                                </button>
                            </div>
                        </div>

                    </div>

                        <div className='table-responsive table-scrollbar_esp mb-4'>
                            <table className="table table-sm table-striped table-hover">
                                <thead>
                                    <tr className='th-sticky' >
                                        {columns.map((prop, index) => (
                                            <th key={index} scope="col" className={prop.classHeader} onClick={() => requestSort(prop.dataIndex)}>
                                                {prop.header}
                                                {sortConfig && sortConfig.key === prop.dataIndex && (
                                                    <span>{sortConfig.direction === 'asc' ? ' ▲' : ' ▼'}</span>
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentPageItems.length == 0 ? <>
                                            <tr>
                                                <td colSpan={columns.length}>No se encontraron Ordenes</td>
                                            </tr>
                                        </> :
                                            currentPageItems.map((row, index) => (
                                                <tr key={index} style={{ cursor: 'pointer' }}
                                                    onDoubleClick={() => function_(row)}
                                                >
                                                    {
                                                        columns.map((column, i) => (
                                                            <td key={i} className={column.classRow}>
                                                                {
                                                                    column.renderer ? column.renderer(row[column.dataIndex], row) : row[column.dataIndex]
                                                                }
                                                            </td>
                                                        ))
                                                    }
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <ReactPaginate
                            containerClassName="pagination pagination-sm"
                            pageClassName="page-item" pageLinkClassName="page-link"
                            activeClassName={'active'} previousClassName="page-item"
                            previousLinkClassName="page-link"
                            previousLabel={'<'} nextLabel={'>'}
                            nextClassName="page-item" nextLinkClassName="page-link"
                            breakLabel={'...'} breakClassName={'break-me'}
                            pageCount={Math.ceil(sortedData.length / perPage)}
                            marginPagesDisplayed={5} pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                        />
                    </> :
                    <>
                        {
                            (msg == "") ?
                                <>
                                    <div className="alert alert-light fw-bold border-primary text-primary mt-3 mb-5" role="alert">
                                        <AlertTriangle size="18" color="#dc3545" />
                                        <span className='text-left-10'>Favor de ingresar parámetros de búsqueda y dar clic en el botón Buscar de arriba</span>
                                    </div>
                                </> :
                                <>
                                    <div className="alert alert-warning fw-bold mt-3 mb-5" role="alert">
                                        <AlertTriangle size="18" color="#664d03" />
                                        <span className='text-left-10'>{msg}</span>
                                    </div>
                                </>
                        }

                    </>
                }
            </>
        )
    }


}
