import React, { useEffect } from 'react'
import { AlertTriangle, CheckCircle, CheckSquare, Clock, CreditCard, DollarSign, List, Search, UserCheck, UserPlus } from 'react-feather'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { clearStoreReport, startloadSumaryOrders } from '../../../../app/store/reportes/thunks';
import { Table } from '../../../utils/Table';
import { RiFileExcel2Line } from 'react-icons/ri';
import * as XLSX from 'xlsx/xlsx.mjs';
import { Link } from 'react-router-dom';
import { MDetail } from '../modals/MDetail';
import { startloadOrden } from '../../../../app/store/ordenes/thunks';
import { startloadSale, startloadSaleReport } from '../../../../app/store/sales/thunks';


export const SumaryOrders = () => {



    const { register, handleSubmit, watch, reset, getValues, setValue, formState: { errors } } = useForm({ model: 'all' });
    const { isLoading, status_report, sumaryOrders } = useSelector(state => state.reportes);

    useEffect(() => {
        dispatch(clearStoreReport())
    }, []);


    let f = new Date(); let dia = f.getDate(); let mes = f.getMonth() + 1; if (dia < 10) dia = "0" + dia; if (mes < 10) mes = "0" + mes; let today = f.getFullYear() + "-" + mes + "-" + dia;
    const dispatch = useDispatch();

    const searchOrdenes = (data) => {
        dispatch(startloadSumaryOrders(data.fechaInicial, data.fechaFinal, data.opcionReporte))
    }

    const loadFolio = (folio) => {

        if (folio.includes("HDLV")) {
            // console.log("La cadena no contiene 'bienvenido'");
            dispatch(startloadSaleReport(folio))
        } else {

            dispatch(startloadOrden(folio));
        }

    }

    let sum = 0; let sumConteoOrdenes = 0; let sumTotal = 0;

    sumaryOrders["ORDENES"]?.forEach(item => {
        sum = sum + Number(item.GANANCIA);
    });

    sumaryOrders["GLOBALES"]?.forEach(it => {
        sumConteoOrdenes = sumConteoOrdenes + Number(it.CONTEO);
        sumTotal = sumTotal + Number(it.GANANCIA);
    });


    let resultadoRecuerrentes = []; let sumRecurrentes = 0; let sumConteoRecurrentes = 0;
    // Asegúrate de que sumaryOrders y sumaryOrders["ORDENES"] estén definidos
    if (sumaryOrders && sumaryOrders["ORDENES"]) {
        let cRecurrentes = sumaryOrders["ORDENES"].filter(item => item.TIPO_CLIENTE === "Recurrente");

        // Agrupar por CONOCIERON_POR y acumular GANANCIA
        const agrupados = cRecurrentes.reduce((acc, item) => {
            const fuente = item.CONOCIERON_POR;
            const ganancia = Number(item.GANANCIA); // Asegúrate de convertir a número

            // Si no existe la categoría en el acumulador, inicializarla
            if (!acc[fuente]) {
                acc[fuente] = { CONOCIERON_POR: fuente, GANANCIA: 0, CONTEO: 0 }; // Agregar conteo
            }

            // Sumar la ganancia correspondiente
            acc[fuente].GANANCIA += ganancia;
            sumRecurrentes = sumRecurrentes + ganancia;
            sumConteoRecurrentes = sumConteoRecurrentes + 1;

            // Incrementar el conteo de órdenes para la fuente
            acc[fuente].CONTEO += 1;

            return acc;
        }, {});

        resultadoRecuerrentes = Object.values(agrupados);
    }


    let resultadoNuevo = []; let sumNuevo = 0; let sumConteoNuevo = 0;
    // Asegúrate de que sumaryOrders y sumaryOrders["ORDENES"] estén definidos
    if (sumaryOrders && sumaryOrders["ORDENES"]) {
        let cNuevos = sumaryOrders["ORDENES"].filter(item => item.TIPO_CLIENTE === "Nuevo");

        // Agrupar por CONOCIERON_POR y acumular GANANCIA
        const agrupados = cNuevos.reduce((acc, item) => {
            const fuente = item.CONOCIERON_POR;
            const ganancia = Number(item.GANANCIA); // Asegúrate de convertir a número

            // Si no existe la categoría en el acumulador, inicializarla
            if (!acc[fuente]) {
                acc[fuente] = { CONOCIERON_POR: fuente, GANANCIA: 0, CONTEO: 0 }; // Agregar conteo
            }

            // Sumar la ganancia correspondiente
            acc[fuente].GANANCIA += ganancia;
            sumNuevo = sumNuevo + ganancia;
            sumConteoNuevo = sumConteoNuevo + 1;

            // Incrementar el conteo de órdenes para la fuente
            acc[fuente].CONTEO += 1;

            return acc;
        }, {});

        resultadoNuevo = Object.values(agrupados);
    }


    // Sumario
    let granConteoTotal = sumConteoNuevo + sumConteoRecurrentes;
    let granTotal = sumNuevo + sumRecurrentes;

    const exportToXLS = (data) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `Sumario_Ordenes_Servicio.xlsx`);
    }

    // const tipoOrden = watch("opcionReporte");

    // console.log(tipoOrden);





    return (
        <>
            <div>

                
                <h2 className="h4 mb-3 fw-bold">Sumario de Ordenes: Servicio / Ventas</h2>

                <div className="">

                    <div className="card-body">
                        <form className='row' onSubmit={handleSubmit(searchOrdenes)} >
                            <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                <label className="form-label">Fecha de Inicio:</label>
                                <input type="date" className="form-control" {...register("fechaInicial", { required: 'Introduce fecha de inicio' })} />
                                {
                                    errors.fechaInicial &&
                                    <div className='text-left-5 mt-2'>
                                        <AlertTriangle size="18" color="#dc3545" />
                                        <span className='text-left-10 fw-bold text-danger'><small>{errors.fechaInicial.message}</small></span>
                                    </div>
                                }
                            </div>
                            <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2  ">
                                <label className="form-label">Fecha de Termino:</label>
                                <input type="date" className="form-control" defaultValue={today} {...register("fechaFinal")} />
                            </div>

                            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-1">
                                <div className="mb-3 mt-4">
                                    <button type="submit" className="btn btn-outline-secondary">
                                        <Search size="20" /> Buscar
                                    </button>
                                </div>
                            </div>

                            <div className='d-flex'>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio"  {...register("opcionReporte")} value="1" defaultChecked />
                                    <label className="form-check-label" >
                                        Ordenes de Servicio
                                    </label>
                                </div>
                                <div className="form-check ms-3 ">
                                    <input className="form-check-input" type="radio"  {...register("opcionReporte")} value="2" />
                                    <label className="form-check-label" >
                                        Ordenes de Venta
                                    </label>
                                </div>

                            </div>
                        </form>
                        <hr className='my-0' />
                        {/* Summarys */}
                        <div className='row'>
                            <div className=' col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                <div className="card mt-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title text-dark">Ganacias Totales</h5>
                                            </div>
                                            <div className="col-auto">
                                                <div className="stat text-primary">
                                                    <DollarSign size='20' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <h1 className="mt-1 mb-3">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(sum)}</h1>


                                            <table className="table table-hover table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className='text-start'>Tipo de Cliente:</th>
                                                        <th scope="col" className='text-center'>Conteo</th>
                                                        <th scope="col" className='text-end'> Ganancias </th>

                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    <tr>
                                                        <td className='text-start'>Clientes Nuevos</td>
                                                        <td className='text-center'>{sumConteoNuevo}</td>
                                                        <td className='text-end'>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(sumNuevo)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='text-start'>Clientes Recurrentes</td>
                                                        <td className='text-center'>{sumConteoRecurrentes}</td>
                                                        <td className='text-end'>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(sumRecurrentes)}</td>
                                                    </tr>



                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={1} className='fw-bold'>Totales</td>
                                                        <td colSpan={1} className='fw-bold'>{granConteoTotal}</td>
                                                        <td colSpan={1} className='fw-bold text-end'>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(granTotal)}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className=' col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                <div className="card mt-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title text-dark">Clientes Nuevos: {sumConteoNuevo}</h5>
                                            </div>
                                            <div className="col-auto">
                                                <div className="stat text-primary">
                                                    <UserPlus size='20' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <table className="table table-hover table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className='text-start'>Nos conocio por:</th>
                                                        <th scope="col" className='text-center'>Conteo</th>
                                                        <th scope="col" className='text-end'> Ganancia </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        resultadoNuevo?.length == 0 ?
                                                            <>
                                                                <tr>
                                                                    <td colSpan={3}>No hay datos por mostrar</td>
                                                                </tr>
                                                            </> :
                                                            resultadoNuevo?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td className="text-start">{item.CONOCIERON_POR}</td>
                                                                    <td className="text-center">{item.CONTEO}</td>
                                                                    <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.GANANCIA)}</td>
                                                                </tr>
                                                            ))
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={1} className='fw-bold'>Totales</td>
                                                        <td colSpan={1} className='fw-bold'>{sumConteoNuevo}</td>
                                                        <td colSpan={1} className='fw-bold text-end'>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(sumNuevo)}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className=' col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                <div className="card mt-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title text-dark">Clientes recurrentes: {sumConteoRecurrentes}</h5>
                                            </div>
                                            <div className="col-auto">
                                                <div className="stat text-primary">
                                                    <UserCheck size={20} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='text-center'>

                                            <table className="table table-hover table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className='text-start'>Nos conocio por:</th>
                                                        <th scope="col" className='text-center'>Conteo</th>
                                                        <th scope="col" className='text-end'> Ganancias </th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        resultadoRecuerrentes?.length == 0 ?
                                                            <>
                                                                <tr>
                                                                    <td colSpan={3}>No hay datos por mostrar</td>
                                                                </tr>
                                                            </> :
                                                            resultadoRecuerrentes?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td className="text-start">{item.CONOCIERON_POR}</td>
                                                                    <td className="text-center">{item.CONTEO}</td>
                                                                    <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.GANANCIA)}</td>
                                                                </tr>
                                                            ))
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td colSpan={1} className='fw-bold'>Totales</td>
                                                        <td colSpan={1} className='fw-bold'>{sumConteoRecurrentes}</td>
                                                        <td colSpan={1} className='fw-bold text-end'>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(sumRecurrentes)}</td>
                                                    </tr>
                                                </tfoot>
                                            </table>

                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                            {
                                (isLoading) ?
                                    <>
                                        <div className='div_center'>
                                            <div className='text-center'>
                                                <div className="spinner"></div>
                                            </div>
                                            <div className='text-center text-left-15'>
                                                <span className='fw-bold'>Cargando</span>
                                            </div>
                                            <div className='text-center text-left-15'>
                                                <div className="spinner"></div>
                                            </div>
                                        </div>
                                    </> :
                                    <>
                                        <div className='d-flex justify-content-between flex-md-nowrap align-items-center pt-3 pb-1 mb-1 border-bottom'>
                                            <h5 className='fw-bold'>Listado de Ordenes de Servicio</h5>

                                            <button type="button" className="btn btn-outline-success border-0 mt-1 fw-bold" onClick={() => exportToXLS(sumaryOrders["ORDENES"])}>
                                                <RiFileExcel2Line size={25} /> <span className='text-left-5 align-middle'> Exportar </span>
                                            </button>

                                        </div>
                                        <div className="table-responsive my-0 table-scrollbar_esp_reporte mt-3">



                                            <table className="table table-striped table-hover table-sm">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="text-center th-sticky">#</th>
                                                        <th scope="col" className="text-center th-sticky">FOLIO</th>
                                                        <th scope="col" className="text-start th-sticky">CLIENTE</th>
                                                        <th scope="col" className="text-center th-sticky">TIPO</th>
                                                        <th scope="col" className="text-center th-sticky">F.INGRESO / F. VENTA</th>
                                                        <th scope="col" className="text-center th-sticky">F.CERRADA</th>
                                                        <th scope="col" className="text-center th-sticky">NOS CONOCIERON</th>
                                                        <th scope="col" className="text-center th-sticky">ESTADO</th>
                                                        <th scope="col" className="text-end th-sticky">GANANCIA($)</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="">
                                                    {
                                                        sumaryOrders["ORDENES"]?.length == 0 ?
                                                            <>
                                                                <tr>
                                                                    <td colSpan={8}>{status_report}</td>
                                                                </tr>
                                                            </> :
                                                            sumaryOrders["ORDENES"]?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td className="text-center">
                                                                        <button type="button" className="btn btn-link" data-bs-toggle="modal" data-bs-target="#detailFolio" onClick={() => loadFolio(item.FOLIO)}>{item.FOLIO}</button>
                                                                    </td>
                                                                    <td className="text-start">{item.NOMBRE} | Alta en sistema: {item.FECHA_ALTA_CLIENTE}</td>
                                                                    <td className="text-center">{item.TIPO_CLIENTE}</td>
                                                                    <td className="text-center">{item.FECHA_REGISTRO}</td>
                                                                    <td className="text-center">{item.FECHA_CERRADA || ' - '}</td>
                                                                    <td className="text-center">{item.CONOCIERON_POR}</td>
                                                                    <td className="text-center"> {item.DESCRIPCION_ESTADO || ' - '}</td>
                                                                    <td className="text-end"> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.GANANCIA)}</td>

                                                                </tr>
                                                            ))
                                                    }
                                                </tbody>
                                            </table>



                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
