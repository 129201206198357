import React from 'react'
import { AlertTriangle, List, Search } from 'react-feather';
import { useForm } from 'react-hook-form';
import { RiFileExcel2Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { startloadPerformance } from '../../../../app/store/reportes/thunks';
import * as XLSX from 'xlsx/xlsx.mjs';
import { startloadSaleReport } from '../../../../app/store/sales/thunks';
import { startloadOrden } from '../../../../app/store/ordenes/thunks';
import { Table_2 } from '../../../utils/Table_2';
export const Performance = () => {

    const { register, handleSubmit, watch, reset, getValues, setValue, formState: { errors } } = useForm({ model: 'all' });
    const { empleados } = useSelector(state => state.empleados);
    const { isLoading, status_report, performance, performanceTotales } = useSelector(state => state.reportes);
    let f = new Date(); let dia = f.getDate(); let mes = f.getMonth() + 1; if (dia < 10) dia = "0" + dia; if (mes < 10) mes = "0" + mes; let today = f.getFullYear() + "-" + mes + "-" + dia;

    const dispatch = useDispatch();
    // console.log(empleados);


    const searchOrdenes = (data) => {
        // console.log(data);
        dispatch(startloadPerformance(data.fechaInicial, data.fechaFinal, data.tecnico))
    }
    const exportToXLS = (data) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `Reporte_Rendimiento.xlsx`);
    }


    const loadFolio = (folio) => {

        if (folio.includes("HDLV")) {
            dispatch(startloadSaleReport(folio))
        } else {
            dispatch(startloadOrden(folio));
        }

    }



    const dataU = [...performance];
    const columnsType1 = [
        {
            header: 'FOLIO',
            dataIndex: 'FOLIO',
            classHeader: 'text-center',
            classRow: 'text-center fw-bold text-primary',
            renderer: (value) => (
                <>
                    <button type="button" className="btn btn-link" data-bs-toggle="modal" data-bs-target="#detailFolio" onClick={() => loadFolio(value)}>{value}</button>
                </>
            ),
        },

        {
            header: 'TECNICO',
            dataIndex: 'TECNICOASIGNADO',
            classHeader: 'text-center',
            classRow: 'text-center',
        },
        {
            header: 'ASIGNACIÓN',
            dataIndex: 'TIEMPO_ESTADO_2',
            classHeader: 'text-center',
            classRow: 'text-center',
        },
        {
            header: 'DIAGNOSTICO',
            dataIndex: 'TIEMPO_ESTADO_3',
            classHeader: 'text-center',
            classRow: 'text-center',
        },

        {
            header: 'E.COMPONENTE',
            dataIndex: 'TIEMPO_ESTADO_4',
            classHeader: 'text-center',
            classRow: 'text-center',
        },
        {
            header: 'TERMINADA',
            dataIndex: 'TIEMPO_ESTADO_5',
            classHeader: 'text-center',
            classRow: 'text-center',
        },
        {
            header: 'APROBADA ENTREGA',
            dataIndex: 'TIEMPO_ESTADO_7',
            classHeader: 'text-center',
            classRow: 'text-center',
        },
        {
            header: 'CERRADA',
            dataIndex: 'TIEMPO_ESTADO_8',
            classHeader: 'text-center',
            classRow: 'text-center',
        },




    ];



    return (
        <div>
            <h2 className="h4  fw-bold">Rendimiento</h2>
            <div>
                <div className="card-body">

                    <form className='row' onSubmit={handleSubmit(searchOrdenes)} >

                        <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-2'>
                            <label className="form-label">Tecnico:</label>

                            <select className="form-select " defaultValue={'0'}  {...register("tecnico")} >
                                <option value={'0'} >TODOS</option>
                                {
                                    empleados.map((item, index) => (
                                        item.ESTADO == 1 && item.ROL_ID == 3 ? <option key={index} value={item.ID} className='text-uppercase' >({item.USER}) | {item.NOMBRE} </option>
                                            : <option key={index} style={{ display: "none" }} ></option>
                                    ))
                                }
                            </select>
                        </div>
                        <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                            <label className="form-label">Fecha de Inicio:</label>
                            <input type="date" className="form-control" {...register("fechaInicial", { required: 'Introduce fecha de inicio' })} />
                            {
                                errors.fechaInicial &&
                                <div className='text-left-5 mt-2'>
                                    <AlertTriangle size="18" color="#dc3545" />
                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.fechaInicial.message}</small></span>
                                </div>
                            }
                        </div>
                        <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2  ">
                            <label className="form-label">Fecha de Termino:</label>
                            <input type="date" className="form-control" defaultValue={today} {...register("fechaFinal")} />
                        </div>

                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-1">
                            <div className="mb-3 mt-4">
                                <button type="submit" className="btn btn-outline-secondary">
                                    <Search size="20" /> Buscar
                                </button>
                            </div>
                        </div>
                    </form>
                    <hr className='my-0' />


                    <div className=' col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                        <div className=" mt-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col mt-0">
                                        <h5 className="card-title text-dark">PROMEDIO POR ESTADO: </h5>
                                    </div>

                                </div>
                                <div className='text-center'>
                                    <table className="table table-hover table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col" className='text-start'>ASIGNACION</th>
                                                <th scope="col" className='text-center'>DIAGNOSTICO</th>
                                                <th scope="col" className='text-center'>E.COMPONENTE</th>
                                                <th scope="col" className='text-center'>TERMINADA</th>
                                                <th scope="col" className='text-center'>APROBADA ENTREGA</th>
                                                <th scope="col" className='text-center'>CERRADA</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                performanceTotales?.length == 0 ?
                                                    <>
                                                        <tr>
                                                            <td colSpan={6}>No hay datos por mostrar</td>
                                                        </tr>
                                                    </> :
                                                    performanceTotales?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-start">{item.TIEMPO_PROMEDIO_ESTADO_2}</td>
                                                            <td className="text-center">{item.TIEMPO_PROMEDIO_ESTADO_3}</td>
                                                            <td className="text-center">{item.TIEMPO_PROMEDIO_ESTADO_4}</td>
                                                            <td className="text-center">{item.TIEMPO_PROMEDIO_ESTADO_5}</td>
                                                            <td className="text-center">{item.TIEMPO_PROMEDIO_ESTADO_7}</td>
                                                            <td className="text-center">{item.TIEMPO_PROMEDIO_ESTADO_8}</td>
                                                           
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>

                                    </table>

                                </div>

                            </div>
                        </div>
                    </div>

                    <h5 className='fw-bold'>Resultados</h5>

                    <Table_2 typeTable={3} columns={columnsType1} data={dataU} msg={'En espera para mostrar datos'} />

                </div>
            </div>




        </div>
    )
}
