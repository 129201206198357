import React from 'react'
import { AlertOctagon, AlertTriangle, ArrowLeft, Printer, Save } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { M_GaleriaGarantia } from '../../../utils/M_GaleriaGarantia';
import Swal from 'sweetalert2';
import { startAprobacionnGarantia, startAsignacionGarantia, startCerrarGarantia, startCompletaGarantia, startloadGarantias, startRechazoGarantia } from '../../../../app/store/garantias/thunk';
import { useForm } from 'react-hook-form';

export const EditWarrantie = () => {
  const { garantia_edit, isLoading, status } = useSelector(state => state.garantias);
  const { empleados } = useSelector(state => state.empleados);
  const { usuario, id_emp } = useSelector(state => state.usuario);
  const dispatch = useDispatch();

  let garantiaDetail = garantia_edit[0];
  let servicios = garantiaDetail.SERVICIOS;
  // console.log(garantiaDetail.ESTADO);
  console.log(garantiaDetail);

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({ model: 'all' })

  const goback = () => {
    dispatch(startloadGarantias());
  }


  const handleChange = (e, folio) => {

    let id_empleado = e.target.value;

    Swal.fire({
      title: 'Atención',
      text: "Está por Asignar la garantia a un empleado, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startAsignacionGarantia(usuario, id_empleado, folio))
      }
    })
  }

  const onSave = (data) => {

    if (garantiaDetail.TECNICO_ASIGNADO == null || garantiaDetail.TECNICO_ASIGNADO == '') {
      Swal.fire({
        title: "Atencion",
        text: "1 Debe asignar la solicitud a un Tecnico, para que este apruebe o rechace la solicitud",
        icon: "error"
      });

      
    }else{

 
      if (data.opcionReporte == 1) {

        Swal.fire({
          title: 'Atención',
          text: "Está por Aprobar la solicitud de garantia, ¿Desea de continuar con esta acción?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3b7ddd',
          cancelButtonColor: '#dc3545',
          cancelButtonText: 'No',
          confirmButtonText: 'Si'
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(startAprobacionnGarantia(usuario, garantiaDetail.FOLIO, data.observacionesAprueba))
          }
        })

      }

      if (data.opcionReporte == 2) {

        Swal.fire({
          title: 'Atención',
          text: "Está por Rechazar la solicitud de garantia, ¿Desea de continuar con esta acción?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3b7ddd',
          cancelButtonColor: '#dc3545',
          cancelButtonText: 'No',
          confirmButtonText: 'Si'
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(startRechazoGarantia(usuario, garantiaDetail.FOLIO, data.observacionesAprueba))
          }
        })

      }
    }


  }

  const onSave2 = (data) => {
    // console.log(data);

    Swal.fire({
      title: 'Atención',
      text: "Está por Marcar como Completa la solicitud de garantia, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startCompletaGarantia(usuario, garantiaDetail.FOLIO, data.observacionesCompleta))
      }
    })
  }

  const onSave3 = (data) => {
    // console.log(data);

    Swal.fire({
      title: 'Atención',
      text: "Está por Cerrar la solicitud de garantia, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startCerrarGarantia(garantiaDetail.FOLIO, data.observacionesCerrar))
      }
    })
  }


  const launchreportE = (e) => {
    // console.log(garantiaDetail.FOLIO);
    window.open(`./assets/reports/compGarantiaEntrega.html?folio=${e}`, '_blank');
  }



  return (
    <div className='row'>
      <div className='col-12'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item btn-link" onClick={goback}><ArrowLeft size='20' /> Atras</li>
            <li className="breadcrumb-item active" aria-current="page">Detalles de la Solicitud de Garantia</li>
          </ol>
        </nav>
      </div>

      <div className='col-12'>
        {/* <ViewEvidencias /> <Galeria /> */}
        <M_GaleriaGarantia />
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
            <div className="card ">
              <div className="card-body">
                <label className="mb-1 fw-bold text-dark">Solicitud de Garantia:</label>
                <div className="d-flex">
                  <input className="form-control me-2" type="search" aria-label="Search" disabled value={garantiaDetail.FOLIO} />

                </div>
              </div>
              <hr className="my-0" />
              <div className="card-body">
                <div className="row mb-1">
                  <div className="col-12">
                    <h5 className="fw-bold me-2 border-0">Datos del Cliente </h5>
                  </div>
                </div>
                <hr className="my-0" />
                <div className="row">
                  <div className="col-12 mt-3">
                    <div className="mb-1">
                      <label className="form-label">Nombre:</label>
                      <input type="text" className="form-control" value={garantiaDetail.NOMBRE} disabled />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-1">
                      <label className="form-label">Telefono:</label>
                      <input type="text" className="form-control" value={garantiaDetail.TELEFONO} disabled />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-1">
                      <label className="form-label">Celular:</label>
                      <input type="text" className="form-control" value={garantiaDetail.CELULAR} disabled />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="form-label">Correo electronico:</label>
                      <input type="text" className="form-control" value={garantiaDetail.EMAIL} disabled />
                    </div>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-12">
                    <h5 className="fw-bold me-2 border-0">Equipo </h5>
                    <hr className="my-0" />
                  </div>
                  <div className="col-12 mt-1">
                    <div className="mb-2">
                      <label className="form-label">Equipo:</label>
                      <input type="text" className="form-control" value={garantiaDetail.EQUIPO} disabled />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-2">
                      <label className="form-label">S.N:</label>
                      <input type="text" className="form-control" value={garantiaDetail.NO_SERIE} disabled />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-2">
                      <label className="form-label">Sistema Operativo:</label>
                      <input type="text" className="form-control" value={garantiaDetail.SIS_OP} disabled />
                    </div>
                  </div>


                  <div className="col-12 mt-2">
                    <button type="button" className="btn btn-outline-dark border-0 w-100" data-bs-toggle="modal" data-bs-target="#galeryWarranties">Ver fotografias</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
            <div className="card">

              <div className="card-body">

                {
                  (garantiaDetail.TECNICO_ASIGNADO) == null ?
                    <><h5>Esta Solicitud de garantia aun NO se ha asignado a ningun tecnico. Puede ser asignado...</h5>

                      <div>
                        <select className="form-select " style={{ width: '300px' }} defaultValue={'TODOS'} onChange={e => handleChange(e, garantiaDetail.FOLIO)}  >
                          <option value={'TODOS'} >Seleccione un Tecnico</option>
                          {
                            empleados.map((item, index) => (
                              item.ESTADO == 1 && item.ROL_ID == 3 ? <option key={index} value={item.ID} className='text-uppercase' >({item.USER}) | {item.NOMBRE} </option>
                                : <option key={index} style={{ display: "none" }} ></option>
                            ))
                          }
                        </select>
                      </div>

                    </> : <>
                    </>
                }


                {/* Garantia en Estado 6: Garantia Rechazada No aplica */}
                {
                  (garantiaDetail.ESTADO) == '6' ?
                    <>
                      <div className='col-12 col-sm-12 col-md-10 col-lg-12 col-xl-12 col-xxl-12 '>
                        <div className="alert alert-secondary" role="alert">
                          <AlertOctagon size='20' /> La Solicitud de garantia fue rechazada. Comentarios {garantiaDetail.OBS_RECHAZO}
                          <button type="button" className="btn btn-outline-dark mt-2 w-25" onClick={() => launchreportE(garantiaDetail.FOLIO)}>
                            <Printer size='20' /> Imprimir reporte
                          </button>
                        </div>

                      </div>
                    </> : <></>
                }

                {/* Garantia en Estado 6: Garantia Rechazada No aplica */}
                {
                  (garantiaDetail.ESTADO) == '5' ?
                    <>
                      <div className='col-12 col-sm-12 col-md-10 col-lg-12 col-xl-12 col-xxl-12 '>
                        <div className="alert alert-primary" role="alert">
                          <AlertOctagon size='20' /> La Solicitud de garantia fue Cerrada. El dia {garantiaDetail.FECHA_CERRADA}, a las {garantiaDetail.HORA_CERRADA} hrs. Equipo recogido por: {garantiaDetail.RECOGE}
                          <button type="button" className="btn btn-outline-dark mt-2 w-25" onClick={() => launchreportE(garantiaDetail.FOLIO)}>
                            <Printer size='20' /> Imprimir reporte
                          </button>
                        </div>

                      </div>
                    </> : <></>
                }

                <h5 className="card-title fw-bold text-dark mb-2 mt-2">Servicios con garantia aplicable</h5>
                <hr className="my-0" />

                <div className="table-responsive mt-1 table-scrollbar-6">
                  <table className="table table-sm table-hover">
                    <thead className=''>
                      <tr>
                        <th scope="col" className='th-sticky'>#</th>
                        <th scope="col" className='th-sticky text-start'>SERVICIO</th>
                        <th scope="col" className="text-start th-sticky">DESCRIPCION</th>
                        <th scope="col" className="text-center th-sticky"></th>
                        <th scope="col" className="text-center th-sticky"></th>
                        <th scope="col" className="text-end th-sticky"></th>
                        <th scope="col" className="text-center th-sticky"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* vARIANTE TODOS MENOS ADMIN */}
                      {
                        servicios?.map((item, index) => (
                          <tr key={index} className={''}>
                            <td>{index + 1}</td>
                            <td>{item.NOMBRE}</td>
                            <td className="text-start">{item.DESCRIPCION}</td>
                            <td className="text-center" ></td>
                            <td className="text-center"> </td>
                            <td className="text-center"></td>
                          </tr>
                        ))
                      }
                    </tbody>

                  </table>
                </div>


                <div className='row'>


                  {/* Garantia en Estado 1 y 2: Peticion de Garantia y Revision de Garantia */}

                  {
                    (garantiaDetail.ESTADO) == '1' || (garantiaDetail.ESTADO) == '2' ?
                      <>
                        <div className='col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10 '>
                          <form className='row' onSubmit={handleSubmit(onSave)}>
                            <div className='d-flex'>
                              <div className="form-check">
                                <input className="form-check-input" type="radio"  {...register("opcionReporte")} value="1" defaultChecked />
                                <label className="form-check-label" >
                                  Aprobar  Solicitud
                                </label>
                              </div>
                              <div className="form-check ms-3 ">
                                <input className="form-check-input" type="radio"  {...register("opcionReporte")} value="2" />
                                <label className="form-check-label" >
                                  Rechazar Solicitud
                                </label>
                              </div>

                            </div>
                            <div className="mb-3">
                              <label className="form-label">Observaciones:</label>
                              <textarea className="form-control" rows="6" {...register("observacionesAprueba", { required: 'Observaciones para aprobacion/rechazo son requeridas' })} ></textarea>
                              {
                                errors.observacionesAprueba &&
                                <div className='text-left-5 mt-2'>
                                  <AlertTriangle size="18" color="#dc3545" />
                                  <span className='text-left-10 fw-bold text-danger'><small>{errors.observacionesAprueba.message}</small></span>
                                </div>
                              }
                            </div>

                            <div className="mb-3 mt-2">
                              <button type="submit" className="btn btn-outline-primary">
                                <Save size="20" /> Guardar
                              </button>
                            </div>
                          </form>
                        </div>
                      </> : <></>
                  }

                  {/* Garantia en Estado 3: Garantia Aprobada  */}
                  {
                    (garantiaDetail.ESTADO) == '3' ?
                      <>
                        <div className='col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10 '>
                          <form className='row' onSubmit={handleSubmit(onSave2)}>
                            {/* <h5>Registro de las acciones realizadas</h5> */}
                            <div className="mb-3">
                              <label className="form-label">Registro de las acciones realizadas</label>
                              <textarea className="form-control" rows="6" {...register("observacionesCompleta", { required: 'Observaciones para indicar que se corrijieron posibles fallas son requeridas' })} ></textarea>
                              {
                                errors.observacionesCompleta &&
                                <div className='text-left-5 mt-2'>
                                  <AlertTriangle size="18" color="#dc3545" />
                                  <span className='text-left-10 fw-bold text-danger'><small>{errors.observacionesCompleta.message}</small></span>
                                </div>
                              }
                            </div>

                            <div className="mb-3 mt-2">
                              <button type="submit" className="btn btn-outline-primary">
                                <Save size="20" /> Guardar
                              </button>
                            </div>
                          </form>
                        </div>
                      </> : <></>
                  }

                  {/* Garantia en Estado 4: Garantia Cerrada  */}
                  {
                    (garantiaDetail.ESTADO) == '4' ?
                      <>
                        <div className='col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10 '>
                          <form className='row' onSubmit={handleSubmit(onSave3)}>
                            {/* <h5>Registro de las acciones realizadas</h5> */}
                            <div className="mb-3">
                              <label className="form-label">Recoge Equipo</label>
                              <textarea className="form-control" rows="1" {...register("observacionesCerrar", { required: 'Indicar quien recoge el equipo es obligatorio' })} ></textarea>
                              {
                                errors.observacionesCerrar &&
                                <div className='text-left-5 mt-2'>
                                  <AlertTriangle size="18" color="#dc3545" />
                                  <span className='text-left-10 fw-bold text-danger'><small>{errors.observacionesCerrar.message}</small></span>
                                </div>
                              }
                            </div>

                            <div className="mb-3 mt-2">
                              <button type="submit" className="btn btn-outline-primary">
                                <Save size="20" /> Guardar
                              </button>
                            </div>
                          </form>
                        </div>
                      </> : <></>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}
