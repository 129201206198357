import React, { useEffect } from 'react'
import { EditWarrantie } from './panels/EditWarrantie'
import { useDispatch, useSelector } from 'react-redux';
import { Edit, Eye } from 'react-feather';
import { startloadGarantia, startloadGarantias } from '../../../app/store/garantias/thunk';

export const Warranties = () => {

    const { garantias, isLoading, status } = useSelector(state => state.garantias);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startloadGarantias());
    }, []);

    if (isLoading) {
        return (
            <>
                <div className='div_center'>
                    <div className='text-center'>
                        <div className="spinner"></div>
                    </div>
                    <div className='text-center text-left-15'>
                        <span className='fw-bold'>Cargando</span>
                    </div>
                    <div className='text-center text-left-15'>
                        <div className="spinner"></div>
                    </div>
                </div>
            </>
        )
    }

    // console.log(status);
    // console.log(garantias);

    const handledEdit = (folio) => {
        dispatch(startloadGarantia(folio))
    }






    if (status == 'GARANTIA_CARGADA') {
        return (
            <>
                <EditWarrantie />
            </>
        )
    }

    if (status == 'GARANTIAS_CARGADAS' || status=='No se encontro listado de Solicitudes de Garantias.') {
        return (
            <>
                <div className="row">
                    <h1 className="h3 mb-3 fw-bold">Solicitudes de Garantia</h1>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center  border-bottom mb-3">
                                    <h5 className="card-title mb-3">Listado</h5>

                                </div>
                                <div className="row mt-2">
                                    <div className="table-responsive table-responsive p-2 mt-2 table-scrollbar-3 mb-4">
                                        <table className="table tbale-hover table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">FOLIO ORIGEN</th>
                                                    <th scope="col">FOLIO</th>
                                                    {/* <th scope="col">TECNICO ASIGNADO</th> */}
                                                    <th scope="col" className="text-center">FECHA SOL.</th>
                                                    <th scope="col" className="text-center">HORA SOL.</th>
                                                    {/* <th scope="col" className="text-start">PRODUCTO</th> */}
                                                    {/* <th scope="col" className="text-end">COSTO</th> */}
                                                    <th scope="col" className="text-center">ESTADO</th>
                                                    <th scope="col" className="text-center">ACCIONES</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    garantias.length == 0 ?
                                                        <>
                                                            <tr>
                                                                <td colSpan={7}>No se encontraron datos</td>
                                                            </tr>
                                                        </> :
                                                        garantias.map((item, index) => (
                                                            <tr key={index}>
                                                                <td scope="row">{index + 1}</td>
                                                                <td> {item.FOLIO_ORIGEN} </td>
                                                                <td> {item.FOLIO} </td>
                                                                <td className="text-center">{item.FECHA}</td>
                                                                <td className="text-center">{item.HORA}</td>
                                                                {/* <td className="text-start">{item.PRODUCTO}</td> */}
                                                                {/* <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO)}</td> */}
                                                                <td className="text-center">
                                                                    {
                                                                        (item.ESTADO) == 4 ?
                                                                            <>
                                                                                <span className="status-btn complete-btn"> Completa </span>
                                                                            </> :
                                                                            (item.ESTADO) == 3 ?
                                                                                <>
                                                                                    <span className="status-btn tests-btn"> Aprobada </span>
                                                                                </> :
                                                                                (item.ESTADO) == 2 ?
                                                                                    <>
                                                                                        <span className="status-btn progress-btn"> Revision de Garantia  </span>
                                                                                    </> :
                                                                                    (item.ESTADO) == 1 ?
                                                                                        <>
                                                                                            <span className="status-btn revision-btn"> Peticion de Garantia </span>
                                                                                        </> :
                                                                                        <>
                                                                                            <span className="status-btn pending-btn"> En espera </span>
                                                                                        </>
                                                                    }
                                                                </td>
                                                                <td className="text-center">
                                                                    <button type="button" className="btn btn-outline-primary border-0"
                                                                        onClick={() => handledEdit(item.FOLIO)}
                                                                    >
                                                                        <Edit size='20' /> / <Eye size='20' />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }


    return (
        <>

        </>
    )
}
